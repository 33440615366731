import { 
  Paper, 
  TextField, 
  Button, 
  Typography, 
  Box,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { Link } from 'react-router-dom';
import '../../styles/authform.css';

export default function AuthForm({ 
  title,
  isRegister,
  formData = {},
  error,
  success,
  loading,
  handleInputChange,
  handleSubmit,
  children
}) {
  // Only render login/register form if handleInputChange and handleSubmit are provided
  const shouldRenderLoginForm = handleInputChange && handleSubmit;

  const loginRegisterForm = shouldRenderLoginForm && (
    <>
      <form onSubmit={handleSubmit} className="auth-form">
        <TextField
          label="Username"
          type="text"
          required
          value={formData.username || ''}
          onChange={handleInputChange('username')}
          inputProps={{
            pattern: "[a-zA-Z0-9_]+",
            title: "Username can only contain letters, numbers, and underscores"
          }}
        />
        
        <TextField
          label="Password"
          type="password"
          required
          value={formData.password || ''}
          onChange={handleInputChange('password')}
        />

        {isRegister && (
          <>
            <TextField
              label="Confirm Password"
              type="password"
              required
              value={formData.passwordConfirm || ''}
              onChange={handleInputChange('passwordConfirm')}
            />

            <FormControl>
              <InputLabel>Account Type</InputLabel>
              <Select
                value={formData.role || ''}
                label="Account Type"
                onChange={handleInputChange('role')}
              >
                <MenuItem value="student">Student</MenuItem>
                <MenuItem value="teacher">Teacher</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        
        <Button
          type="submit"
          className="auth-submit-button"
          disabled={loading}
        >
          {title}
        </Button>
      </form>
      
      <Box className="auth-links">
        {isRegister ? (
          <>Already have an account? <Link to="/login">Login</Link></>
        ) : (
          <>Don't have an account? <Link to="/register">Register</Link></>
        )}
      </Box>
    </>
  );

  return (
    <Box className="auth-container">
      <Paper className="auth-paper">
        <Typography className="auth-title">
          {title}
        </Typography>
        
        {error && <Alert severity="error" className="auth-error">{error}</Alert>}
        {success && <Alert severity="success" className="auth-error">{success}</Alert>}
        
        {children || loginRegisterForm}
      </Paper>
    </Box>
  );
}
