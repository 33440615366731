export default function OperationsPractice({ inputs }) {
  const { num1, num2, operation, missingPosition } = inputs;
  
  let answer;
  let question;

  // Convert symbols for display
  const getDisplayOperation = (op) => {
    switch (op) {
      case '*': return 'X';
      case '÷': return '÷';
      default: return op;
    }
  };

  const displayOperation = getDisplayOperation(operation);

  switch (operation) {
    case '+':
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_ ${displayOperation} ${num2} = ${num1 + num2}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1} ${displayOperation} _ = ${num1 + num2}`;
      } else {
        answer = num1 + num2;
        question = `${num1} ${displayOperation} ${num2} = _`;
      }
      break;
    case '-':
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_ ${displayOperation} ${num2} = ${num1 - num2}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1} ${displayOperation} _ = ${num1 - num2}`;
      } else {
        answer = num1 - num2;
        question = `${num1} ${displayOperation} ${num2} = _`;
      }
      break;
    case '*':
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_ ${displayOperation} ${num2} = ${num1 * num2}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1} ${displayOperation} _ = ${num1 * num2}`;
      } else {
        answer = num1 * num2;
        question = `${num1} ${displayOperation} ${num2} = _`;
      }
      break;
    case '÷': // Using the exact same division symbol
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_ ${displayOperation} ${num2} = ${num1 / num2}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1} ${displayOperation} _ = ${num1 / num2}`;
      } else {
        answer = num1 / num2;
        question = `${num1} ${displayOperation} ${num2} = _`;
      }
      break;
    case '^':
      if (missingPosition === 'num1') {
        answer = num1;
        question = `_ ${displayOperation} ${num2} = ${Math.pow(num1, num2)}`;
      } else if (missingPosition === 'num2') {
        answer = num2;
        question = `${num1} ${displayOperation} _ = ${Math.pow(num1, num2)}`;
      } else {
        answer = Math.pow(num1, num2);
        question = `${num1} ${displayOperation} ${num2} = _`;
      }
      break;
    default:
      console.log('Hit default case with operation:', operation);
      answer = 0;
      question = '0 = 0';
  }

  return {
    problem: {
      question,
      answer: answer.toString()
    },
    validateAnswer: (input) => input.match(/^-?\d*\.?\d+$/)
  };
}
