import { roll, randomizeFactors } from './utils';

export const multiplication = {
  "Multidigit Multiplication": {
    levels: [
      { id: "m4p2n8k5", description: "Multiply by 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => ({
        ...randomizeFactors(10, roll(11, 99)),
        operation: '*'
      })}
    ]
  }
};
