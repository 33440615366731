import { Typography, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { exerciseComponents, customUIComponents, inlineInputComponents } from '../config/practiceComponents';
import TestCountingPractice from '../practices/TestCountingPractice';

// Create a modified version of exerciseComponents for the test
const testExerciseComponents = {
  ...exerciseComponents,
  CountingPractice: TestCountingPractice
};

export default function PracticeExercise({ 
  levelData, 
  currentProblem, 
  inputValue, 
  setInputValue, 
  handleAnswer,
  level,
  progress,
  lastProblemRef,
  isTest = false // New prop to indicate if we're in test mode
}) {
  if (!levelData) {
    return <Typography>Invalid level</Typography>;
  }

  // Use test components if in test mode
  const components = isTest ? testExerciseComponents : exerciseComponents;
  const ExerciseComponent = components[levelData.exerciseType];
  if (!ExerciseComponent) {
    return <Typography>Invalid exercise type</Typography>;
  }

  // Special case for components with custom UI
  if (customUIComponents.includes(levelData.exerciseType)) {
    const inputs = levelData.exerciseType === 'VerticalOperationsPractice' && levelData.inputs.generateProblem
      ? levelData.inputs.generateProblem
      : levelData.inputs;
      
    return (
      <ExerciseComponent
        level={parseInt(level, 10)}
        onAnswer={handleAnswer}
        currentProgress={progress.completed}
        inputs={inputs}
        lastProblem={lastProblemRef.current}
      />
    );
  }

  // For other exercises, use common UI with stored problem
  if (!currentProblem) return null;

  // For multiple choice questions
  if (levelData.exerciseType === 'MultipleChoicePractice' && currentProblem.problem.choices) {
    return (
      <div className="content-box">
        <Typography className="practice-title">
          {currentProblem.problem.question}
        </Typography>
        <RadioGroup
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="radio-group"
        >
          {currentProblem.problem.choices.map((choice, index) => (
            <FormControlLabel
              key={index}
              value={choice}
              control={<Radio />}
              label={`${index + 1}. ${choice}`}
              className="radio-option"
            />
          ))}
        </RadioGroup>
      </div>
    );
  }

  // For text input UI
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const correctAnswer = currentProblem.problem.answer;
    const trimmedInput = inputValue.trim();
    
    if (Array.isArray(correctAnswer)) {
      handleAnswer(
        correctAnswer.includes(trimmedInput) ? 'correct' : trimmedInput,
        correctAnswer[0]
      );
    } else {
      handleAnswer(trimmedInput, correctAnswer.trim());
    }
    
    setInputValue('');
  };

  // Special handling for components that need inline input
  if (inlineInputComponents.includes(levelData.exerciseType)) {
    const parts = currentProblem.problem.question.split('_');
    return (
      <form onSubmit={handleSubmit} className="content-box">
        <Typography className="practice-title" style={{ display: 'flex', alignItems: 'center', gap: '8px', justifyContent: 'center' }}>
          {parts[0]}
          <TextField
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            autoFocus
            className="number-input"
            style={{ width: '80px', margin: '0 4px' }}
            size="small"
          />
          {parts[1]}
        </Typography>
      </form>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="content-box">
      <Typography className="practice-title">
        {currentProblem.problem.question}
      </Typography>
      <TextField
        variant="outlined"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        autoFocus
        className="number-input"
      />
    </form>
  );
}
