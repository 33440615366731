import { useState } from 'react';
import {
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert
} from '@mui/material';

export default function ClassList({ 
  classes, 
  loading, 
  error, 
  selectedClass, 
  onCreateClass, 
  onSelectClass 
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [newClassName, setNewClassName] = useState('');

  const handleCreateClass = async () => {
    const success = await onCreateClass(newClassName);
    if (success) {
      setOpenDialog(false);
      setNewClassName('');
    }
  };

  return (
    <>
      <Box className="class-list-header">
        <Typography className="class-list-title">
          Your Classes
        </Typography>
        <Button
          variant="contained"
          onClick={() => setOpenDialog(true)}
          className="create-class-button"
        >
          Create New Class
        </Button>
      </Box>

      {error && (
        <Alert severity="error" className="error-alert">
          {error}
        </Alert>
      )}

      {loading ? (
        <Box className="loading-container">
          <CircularProgress />
        </Box>
      ) : classes.length === 0 ? (
        <Typography className="no-classes-message">
          No classes created yet.
        </Typography>
      ) : (
        <List className="class-list">
          {classes.map((classData) => (
            <div key={classData.id}>
              <ListItem
                button
                onClick={() => onSelectClass(classData)}
                selected={selectedClass?.id === classData.id}
                className={`class-list-item ${selectedClass?.id === classData.id ? 'selected' : ''}`}
              >
                <ListItemText
                  primary={
                    <Typography className="class-name">
                      Class Name: {classData.name}
                    </Typography>
                  }
                  secondary={
                    <Typography className="class-details">
                      Class Code: {classData.code}
                      <br />
                      Students: {(classData.students || []).length}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider className="class-divider" />
            </div>
          ))}
        </List>
      )}

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        className="create-class-dialog"
      >
        <DialogTitle>Create New Class</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Class Name"
            fullWidth
            value={newClassName}
            onChange={(e) => setNewClassName(e.target.value)}
            className="class-name-input"
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button 
            onClick={() => setOpenDialog(false)}
            className="cancel-button"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateClass} 
            variant="contained"
            disabled={!newClassName.trim()}
            className="create-button"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
