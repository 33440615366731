import { roll } from './utils';

export const decimals = {
  "Decimals": {
    levels: [
      { 
        id: "d4n1m2p3", 
        description: "Read and Write Decimals to Tenths", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 45, 
        inputs: () => {
          const whole = roll(0, 99);
          const tenths = roll(0, 9);
          return {
            number: whole + tenths/10,
            places: [0.1]
          };
        }
      },
      { 
        id: "d4n1m2p4", 
        description: "Read and Write Decimals to Hundredths", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 45, 
        inputs: () => {
          const whole = roll(0, 99);
          const hundredths = roll(0, 99);
          return {
            number: whole + hundredths/100,
            places: [0.01]
          };
        }
      }
    ]
  }
};
