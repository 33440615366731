import { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { useParams } from 'react-router-dom';

const ANSWERS_TO_LEVEL_UP = 10;
const DEFAULT_TIME_LIMIT = 30; // fallback if no timeLimit provided

export default function usePracticeProgress(topic, levelId, timeLimit) {
  const { currentUser, recordActivity } = useAuth();
  const { grade } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(1);
  const [key, setKey] = useState(0);
  const [timeLeft, setTimeLeft] = useState(timeLimit || DEFAULT_TIME_LIMIT);
  const [startTime, setStartTime] = useState(null);
  const [levelComplete, setLevelComplete] = useState(false);
  const [completionCount, setCompletionCount] = useState(0);

  // Load initial progress
  useEffect(() => {
    async function fetchProgress() {
      if (!currentUser || !levelId) {
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Get completion count for this level
          const count = userData.levelCompletions?.[levelId] || 0;
          // Add 1 if it's in the completedLevels array but not in levelCompletions
          const additionalCount = (!userData.levelCompletions?.[levelId] && userData.completedLevels?.includes(levelId)) ? 1 : 0;
          setCompletionCount(count + additionalCount);
          setTimeLeft(timeLimit || DEFAULT_TIME_LIMIT);
          setStartTime(Date.now());
          setCompleted(1);
        }
      } catch (error) {
        console.error('Error fetching progress:', error);
        setError('Failed to load progress');
      } finally {
        setLoading(false);
      }
    }

    fetchProgress();
  }, [topic, currentUser, levelId, grade, timeLimit]);

  // Timer effect
  useEffect(() => {
    if (!startTime || levelComplete) return; // Don't run timer if level is complete

    const timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
      const remaining = (timeLimit || DEFAULT_TIME_LIMIT) - elapsedSeconds;

      if (remaining <= 0) {
        setCompleted(1);
        setError('Time\'s up! Progress reset.');
        setTimeout(() => setError(''), 2000);
        setKey(prev => prev + 1);
        setStartTime(Date.now());
      } else {
        setTimeLeft(remaining);
      }
    }, 100);

    return () => clearInterval(timerInterval);
  }, [startTime, timeLimit, levelComplete]);

  const markLevelComplete = async () => {
    if (!currentUser || !levelId) return;

    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // If it's in completedLevels but not in levelCompletions, initialize with 1
        const additionalCount = (!userData.levelCompletions?.[levelId] && userData.completedLevels?.includes(levelId)) ? 1 : 0;
        
        // Update the completion count
        await updateDoc(userRef, {
          [`levelCompletions.${levelId}`]: increment(1 + additionalCount)
        });

        // Update local state
        setCompletionCount(prev => prev + 1);
      }

      setLevelComplete(true);
      setCompleted(ANSWERS_TO_LEVEL_UP);
    } catch (error) {
      console.error('Error marking level complete:', error);
      setError('Failed to save progress');
    }
  };

  const handleAnswer = async (selectedAnswer, correctAnswer) => {
    if (levelComplete) return; // Don't process answers if level is complete
    
    await recordActivity();

    // For array answers, 'correct' string indicates a match was found
    const isCorrect = selectedAnswer === 'correct' || selectedAnswer === correctAnswer;

    if (isCorrect) {
      const newCompleted = completed + 1;

      if (newCompleted === ANSWERS_TO_LEVEL_UP) {
        // Mark the level as completed
        await markLevelComplete();
      } else {
        setCompleted(newCompleted);
        if (topic !== 'counting') {
          setKey(prev => prev + 1);
        }
      }
    } else {
      setCompleted(1);
      setStartTime(Date.now());
      if (topic !== 'counting') {
        setError('Incorrect answer. Progress reset.');
        setTimeout(() => setError(''), 2000);
        setKey(prev => prev + 1); // Add this line to generate new problem on incorrect answer
      }
    }
  };

  const resetProgress = () => {
    setLevelComplete(false);
    setCompleted(1);
    setStartTime(Date.now());
    setTimeLeft(timeLimit || DEFAULT_TIME_LIMIT);
    setKey(prev => prev + 1);
  };

  return {
    loading,
    error,
    progress: { completed },
    key,
    timeLeft,
    handleAnswer,
    ANSWERS_TO_LEVEL_UP,
    levelComplete,
    completionCount,
    resetProgress
  };
}
