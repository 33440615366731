import { Box } from '@mui/material';
import Hero from './Hero';
import Features from './Features';
import Benefits from './Benefits';
import '../../styles/home/Home.css';
import '../../styles/home/Hero.css';
import '../../styles/home/Features.css';
import '../../styles/home/Benefits.css';

export default function Home() {
  return (
    <Box component="main" className="home-wrapper">
      <section className="hero-section">
        <div className="hero-background"></div>
        <div className="hero-content-wrapper">
          <Box className="hero-content">
            <Hero />
          </Box>
        </div>
      </section>
      <Benefits />
      <Features />
    </Box>
  );
}
