import { roll } from './utils';

export const placeValue = {
  "Place Value": {
    levels: [
      { id: "d8l2szz4", description: "Count Up by 1000 from 1000", exerciseType: "CountingPractice", timeLimit: 20, inputs: { start: 1000, increment: 1000 } },
      { id: "d8l2asdp9", description: "Count Down by 1000 from 10000", exerciseType: "CountingPractice", timeLimit: 20, inputs: { start: 10000, increment: -1000 } },
      { id: "k9mgn7p2", description: "Count Up by 1000", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: roll(111, 999), increment: 1000}},
      { id: "kcm4n7r5", description: "Count Down by 1000", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: roll(9001, 9999), increment: -1000}},
      { 
        id: "q9xzd8m2", 
        description: "Place Value of Four Digit Numbers", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 30, 
        inputs: () => {
          return {
            number: roll(1000, 9999),
            places: [10**roll(0,3)]
          }
        }
      },
      { id: "rfzt7y9u2", description: "Convert Up to Thousands", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['one', 'ten', 'hundred', 'thousand'];
        const fromIndex = roll(1, 3);
        const toIndex = roll(0, fromIndex - 1);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        const value = roll(1, 9);
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'place_value'
        };
      }},
      { 
        id: "m7n9p2m6", 
        description: "Place Value of Seven Digit Numbers", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 30, 
        inputs: () => {
          return {
            number: roll(1000000, 9999999),
            places: [10**roll(4,6)]
          }
        }
      }
    ]
  }
};
