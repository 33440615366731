import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth } from '../../contexts/AuthContext';

export default function BackButton({ to, tooltip }) {
  const navigate = useNavigate();
  const { userProfile } = useAuth();

  const defaultTo = userProfile ? '/dashboard' : '/';
  const defaultTooltip = userProfile ? 'Back to Dashboard' : 'Back to Home';

  return (
    <Box className="back-button">
      <Tooltip title={tooltip || defaultTooltip}>
        <IconButton 
          onClick={() => navigate(to || defaultTo)}
          className="back-button-icon"
        >
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
