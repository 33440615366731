import { roll } from './utils';

export const multiDigitOps = {
  "Multi-digit Addition and Subtraction": {
    levels: [
      { 
        id: "v2d9k4m7", 
        description: "Two Digits Plus Two Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 120,
        inputs: () => {
          const num2ones = roll(0, 9);
          const num1ones = roll(0, 9 - num2ones);
          const num2tens = roll(1, 9);
          const num1tens = roll(1, 9 - num2tens);
          return {
            num1: num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '+'
          };
        }
      },
      { 
        id: "p8f3h6l2", 
        description: "Two Digits Minus Two Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 120,
        inputs: () => {
          const num2ones = roll(0, 8);
          const num1ones = roll(num2ones + 1, 9);
          const num2tens = roll(1, 8);
          const num1tens = roll(num2tens + 1, 9);
          return {
            num1: num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '-'
          };
        }
      },
      { 
        id: "m4x7n9q2", 
        description: "Two Digits Plus Two Digits", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => ({
          num1: roll(4,9)*10+roll(4,9),
          num2: roll(4,9)*10+roll(4,9),
          operation: '+'
        })
      },
      { 
        id: "k6y2w8t5", 
        description: "Two Digits Minus Two Digits", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => {
          const num1 = roll(4,9)*10+roll(1,6);
          return {
            num1,
            num2: roll(10, num1 - 3),
            operation: '-'
          };
        }
      },
      { 
        id: "b9r4c7h2", 
        description: "Three Digits Plus Two Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 120,
        inputs: () => {
          const num2ones = roll(0, 9);
          const num1ones = roll(0, 9 - num2ones);
          const num2tens = roll(1, 9);
          const num1tens = roll(0, 9 - num2tens);
          const num1hundreds = roll(1, 9);
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '+'
          };
        }
      },
      { 
        id: "s5g8j3m6", 
        description: "Three Digits Plus Three Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 120,
        inputs: () => {
          const num2ones = roll(0, 9);
          const num1ones = roll(0, 9 - num2ones);
          const num2tens = roll(0, 9);
          const num1tens = roll(0, 9 - num2tens);
          const num2hundreds = roll(1, 9);
          const num1hundreds = roll(0, 9 - num2hundreds);
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2hundreds * 100 + num2tens * 10 + num2ones,
            operation: '+'
          };
        }
      },
      { 
        id: "f2d7l4p9", 
        description: "Three Digits Minus Two Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 120,
        inputs: () => {
          const num2ones = roll(0, 8);
          const num1ones = roll(num2ones + 1, 9);
          const num2tens = roll(1, 8);
          const num1tens = roll(num2tens + 1, 9);
          const num1hundreds = roll(1, 9);
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2tens * 10 + num2ones,
            operation: '-'
          };
        }
      },
      { 
        id: "h8k3n6r2", 
        description: "Three Digits Minus Three Digits (No Regrouping)", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 120,
        inputs: () => {
          const num2ones = roll(0, 8);
          const num1ones = roll(num2ones + 1, 9);
          const num2tens = roll(0, 8);
          const num1tens = roll(num2tens + 1, 9);
          const num2hundreds = roll(1, 8);
          const num1hundreds = roll(num2hundreds + 1, 9);
          return {
            num1: num1hundreds * 100 + num1tens * 10 + num1ones,
            num2: num2hundreds * 100 + num2tens * 10 + num2ones,
            operation: '-'
          };
        }
      },
      { 
        id: "w4y7t2m5", 
        description: "Three Digits Plus Two Digits", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => ({
          num1: roll(100, 999),
          num2: roll(10, 99),
          operation: '+'
        })
      },
      { 
        id: "q9c4x7b2", 
        description: "Three Digits Plus Three Digits", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => ({
          num1: roll(100, 999),
          num2: roll(100, 999),
          operation: '+'
        })
      },
      { 
        id: "j6l2p8f4", 
        description: "Three Digits Minus Two Digits", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => {
          const num1 = roll(100, 999);
          return {
            num1,
            num2: roll(10, 99),
            operation: '-'
          };
        }
      },
      { 
        id: "m3r7h4k9", 
        description: "Three Digits Minus Three Digits", 
        exerciseType: "VerticalOperationsPractice", 
        timeLimit: 180,
        inputs: () => {
          const num1 = roll(100, 999);
          return {
            num1,
            num2: roll(100, num1 - 1),
            operation: '-'
          };
        }
      }
    ]
  }
};
