import { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import TimeStats from './TimeStats';
import GradeProgress from './GradeProgress';
import { Container, Alert, Box } from '@mui/material';
import '../../../styles/studentdashboard.css';

export default function StudentDashboard() {
  const { userProfile, getActivityIntervals } = useAuth();
  const [timeStats, setTimeStats] = useState({
    day: 0,
    week: 0,
    month: 0,
    lifetime: 0
  });

  useEffect(() => {
    const calculateTimeStats = async () => {
      const intervals = await getActivityIntervals();
      const now = Math.floor(Date.now() / 1000);
      const dayStart = now - (24 * 60 * 60);
      const weekStart = now - (7 * 24 * 60 * 60);
      const monthStart = now - (30 * 24 * 60 * 60);

      setTimeStats(prevStats => {
        const newStats = {
          day: 0,
          week: 0,
          month: 0,
          lifetime: 0
        };

        intervals.forEach(interval => {
          const duration = interval.end - interval.start;
          newStats.lifetime += duration;
          if (interval.start >= monthStart) {
            newStats.month += duration;
            if (interval.start >= weekStart) {
              newStats.week += duration;
              if (interval.start >= dayStart) {
                newStats.day += duration;
              }
            }
          }
        });

        if (JSON.stringify(newStats) !== JSON.stringify(prevStats)) {
          return newStats;
        }
        return prevStats;
      });
    };

    calculateTimeStats();
    const intervalId = setInterval(calculateTimeStats, 5000);

    return () => clearInterval(intervalId);
  }, [getActivityIntervals]);

  if (!userProfile || userProfile.role !== 'student') {
    return (
      <Container maxWidth="sm">
        <Alert severity="error" className="access-denied-alert">
          Access denied. This dashboard is only for students.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box className="dashboard-container">
        <TimeStats timeStats={timeStats} />
        <GradeProgress />
      </Box>
    </Container>
  );
}
