import { createContext, useContext, useState, useEffect, useRef } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  updatePassword as updateFirebasePassword,
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth';
import { doc, setDoc, updateDoc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { auth, db } from '../firebase';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const activityTimerRef = useRef(null);
  const currentActivityStartRef = useRef(null);

  async function signup(username, password, role = 'student') {
    // Create a temporary email using the username
    const tempEmail = `${username.toLowerCase()}@temp.com`;
    
    try {
      // First create the auth user
      const userCredential = await createUserWithEmailAndPassword(auth, tempEmail, password);
      
      // Create different user document structure based on role
      const userDoc = {
        username,
        role
      };

      // Add role-specific fields
      if (role === 'student') {
        userDoc.activityIntervals = [];
        userDoc.progress = {}; // Categories will add their own level when started
      } else if (role === 'teacher') {
        userDoc.classes = []; // Only teachers get the classes array
      }
      
      await setDoc(doc(db, 'users', userCredential.user.uid), userDoc);
      return userCredential;
    } catch (error) {
      // If anything fails, clean up by deleting the auth user if it was created
      if (auth.currentUser) {
        await deleteUser(auth.currentUser);
      }
      throw error;
    }
  }

  async function createClass(className) {
    console.log('Starting createClass function');
    
    if (!currentUser || userProfile?.role !== 'teacher') {
      console.error('User validation failed:', { currentUser: !!currentUser, role: userProfile?.role });
      throw new Error('Only teachers can create classes');
    }

    // Generate a random 6-character class code
    const generateClassCode = () => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let code = '';
      for (let i = 0; i < 6; i++) {
        code += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return code;
    };

    const classCode = generateClassCode();
    console.log('Generated class code:', classCode);
    
    try {
      // Generate a unique ID for the class
      const classId = Date.now().toString();
      console.log('Generated class ID:', classId);

      // Create the class document using setDoc instead of addDoc
      const classRef = doc(db, 'classes', classId);
      const classData = {
        name: className,
        code: classCode,
        teacherId: currentUser.uid,
        students: [],
        createdAt: new Date().toISOString()
      };
      
      console.log('Attempting to create class document:', classData);
      await setDoc(classRef, classData);
      console.log('Class document created successfully');

      // Update teacher's classes array
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      console.log('Retrieved user document');
      
      const classes = userDoc.data().classes || [];
      await updateDoc(userRef, {
        classes: [...classes, classId]
      });
      console.log('Updated user document with new class ID');

      return { id: classId, code: classCode };
    } catch (error) {
      console.error('Error in createClass:', error);
      console.error('Error details:', {
        code: error.code,
        message: error.message,
        stack: error.stack
      });
      throw new Error(`Failed to create class: ${error.message}`);
    }
  }

  async function joinClass(classCode) {
    console.log('Starting joinClass function with code:', classCode);

    if (!currentUser || userProfile?.role !== 'student') {
      console.error('User validation failed:', { currentUser: !!currentUser, role: userProfile?.role });
      throw new Error('Only students can join classes');
    }

    try {
      // Find the class with the given code
      console.log('Querying for class with code:', classCode);
      const classesRef = collection(db, 'classes');
      const q = query(classesRef, where('code', '==', classCode.toUpperCase()));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        console.error('No class found with code:', classCode);
        throw new Error('Invalid class code');
      }

      const classDoc = querySnapshot.docs[0];
      const classData = classDoc.data();
      console.log('Found class:', classData);

      // Check if student is already in the class
      if (classData.students.includes(currentUser.uid)) {
        console.log('Student already enrolled in class');
        throw new Error('You are already enrolled in this class');
      }

      // Update the class document with the new student
      console.log('Updating class with new student');
      const classRef = doc(db, 'classes', classDoc.id);
      
      // Create a new students array with the current user added
      const updatedStudents = [...classData.students, currentUser.uid];
      
      // Only update the students array
      await updateDoc(classRef, {
        students: updatedStudents
      });
      
      console.log('Successfully joined class');

    } catch (error) {
      console.error('Error in joinClass:', error);
      console.error('Error details:', {
        code: error.code,
        message: error.message,
        stack: error.stack
      });
      throw error; // Throw the original error to preserve the Firebase error details
    }
  }

  async function login(username, password) {
    const tempEmail = `${username.toLowerCase()}@temp.com`;
    return signInWithEmailAndPassword(auth, tempEmail, password);
  }

  async function reauthenticate(currentPassword) {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);
  }

  async function updatePassword(currentPassword, newPassword) {
    await reauthenticate(currentPassword);
    return updateFirebasePassword(auth.currentUser, newPassword);
  }

  async function closeActivityInterval() {
    if (currentActivityStartRef.current && currentUser && userProfile?.role === 'student') {
      const endTime = Math.floor(Date.now() / 1000);
      const userRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const intervals = userDoc.data().activityIntervals || [];
        await updateDoc(userRef, {
          activityIntervals: [
            ...intervals,
            {
              start: currentActivityStartRef.current,
              end: endTime
            }
          ]
        });
      }
      
      currentActivityStartRef.current = null;
      if (activityTimerRef.current) {
        clearTimeout(activityTimerRef.current);
        activityTimerRef.current = null;
      }
    }
  }

  async function logout() {
    await closeActivityInterval();
    setUserProfile(null);
    return signOut(auth);
  }

  async function recordActivity() {
    if (!currentUser || userProfile?.role !== 'student') return;

    // Clear existing timer if any
    if (activityTimerRef.current) {
      clearTimeout(activityTimerRef.current);
      activityTimerRef.current = null;
    }

    // Start new interval if none exists
    if (!currentActivityStartRef.current) {
      currentActivityStartRef.current = Math.floor(Date.now() / 1000);
    }

    // Set new timer
    activityTimerRef.current = setTimeout(async () => {
      await closeActivityInterval();
    }, 30000); // 30 seconds
  }

  async function getActivityIntervals() {
    if (!currentUser || userProfile?.role !== 'student') return [];
    
    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
    if (!userDoc.exists()) return [];
    
    const intervals = userDoc.data().activityIntervals || [];
    if (currentActivityStartRef.current) {
      return [
        ...intervals,
        {
          start: currentActivityStartRef.current,
          end: Math.floor(Date.now() / 1000)
        }
      ];
    }
    return intervals;
  }

  // Cleanup function to handle unmounting and auth state changes
  const cleanup = () => {
    if (activityTimerRef.current) {
      clearTimeout(activityTimerRef.current);
      activityTimerRef.current = null;
    }
    if (currentActivityStartRef.current) {
      closeActivityInterval();
      currentActivityStartRef.current = null;
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      // Clean up previous user's activity if exists
      cleanup();
      
      setCurrentUser(user);
      
      if (user) {
        // Fetch user profile from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserProfile(userDoc.data());
        }
      } else {
        setUserProfile(null);
      }
      
      setLoading(false);
    });

    // Cleanup on unmount
    return () => {
      unsubscribe();
      cleanup();
    };
    // eslint-disable-next-line
  }, []);

  // Add cleanup on window unload
  useEffect(() => {
    const handleUnload = () => {
      if (currentActivityStartRef.current) {
        closeActivityInterval();
      }
    };

    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
    // eslint-disable-next-line
  }, []);

  const value = {
    currentUser,
    userProfile,
    signup,
    login,
    logout,
    updatePassword,
    recordActivity,
    getActivityIntervals,
    createClass,
    joinClass
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
