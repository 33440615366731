import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import gradeContent from '../components/grades/gradeContent';

const useGradeProgress = (grade) => {
  const [levelCompletions, setLevelCompletions] = useState({});
  const [loading, setLoading] = useState(true);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const { currentUser } = useAuth();

  useEffect(() => {
    const loadProgress = async () => {
      if (!currentUser) {
        setLoading(false);
        return;
      }

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Get the map of level completions
          const completions = { ...userData.levelCompletions } || {};
          
          // Add 1 to the count for levels in completedLevels that aren't in levelCompletions
          if (userData.completedLevels) {
            userData.completedLevels.forEach(levelId => {
              if (!completions[levelId]) {
                completions[levelId] = 1;
              } else {
                completions[levelId] += 1;
              }
            });
          }
          
          setLevelCompletions(completions);

          // Calculate completion percentage
          if (grade && gradeContent[grade]) {
            let totalLevels = 0;
            let completedCount = 0;

            // Count total levels and check completed ones
            Object.values(gradeContent[grade]).forEach((topicData) => {
              topicData.levels.forEach(level => {
                totalLevels++;
                if (completions[level.id] && completions[level.id] > 0) {
                  completedCount++;
                }
              });
            });

            const percentage = totalLevels > 0 ? Math.round((completedCount / totalLevels) * 100) : 0;
            setCompletionPercentage(percentage);
          }
        } else {
          setLevelCompletions({});
          setCompletionPercentage(0);
        }
      } catch (error) {
        console.error('Error loading grade progress:', error);
        setLevelCompletions({});
        setCompletionPercentage(0);
      }
      
      setLoading(false);
    };

    loadProgress();
  }, [currentUser, grade]);

  const getLevelCompletions = (topicName, levelIndex) => {
    // Get the level ID from grade content
    const levelData = gradeContent[grade]?.[topicName]?.levels[levelIndex - 1];
    if (!levelData) return 0;
    
    // Return the completion count for this level
    return levelCompletions[levelData.id] || 0;
  };

  const isLevelCompleted = (topicName, levelIndex) => {
    return getLevelCompletions(topicName, levelIndex) > 0;
  };

  return {
    isLevelCompleted,
    getLevelCompletions,
    loading,
    completionPercentage
  };
};

export default useGradeProgress;
