import { Box, Container, Grid, Paper } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TimelineIcon from '@mui/icons-material/Timeline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareIcon from '@mui/icons-material/Share';

export default function Features() {
  const features = [
    {
      icon: <MenuBookIcon sx={{ fontSize: 40 }} />,
      title: "Grade-Level Content",
      description: "Content carefully organized and structured by grade level for systematic learning"
    },
    {
      icon: <TimelineIcon sx={{ fontSize: 40 }} />,
      title: "Progress Tracking",
      description: "Track your journey through each grade level with detailed progress monitoring"
    },
    {
      icon: <AccessTimeIcon sx={{ fontSize: 40 }} />,
      title: "Time Management",
      description: "Keep track of time spent learning to maintain consistent progress"
    },
    {
      icon: <ShareIcon sx={{ fontSize: 40 }} />,
      title: "Teacher Integration",
      description: "Share progress and performance data directly with teachers for better support"
    }
  ];

  return (
    <section className="features-section">
      <Container maxWidth="lg">
        <Box className="section-title-wrapper">
          <h2 className="section-title">Features</h2>
        </Box>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper className="feature-card">
                <div className="feature-icon">
                  {feature.icon}
                </div>
                <h3 className="feature-title">{feature.title}</h3>
                <p className="feature-description">{feature.description}</p>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}
