import { useAuth } from '../../../contexts/AuthContext';
import useClassManagement from '../../../hooks/useClassManagement';
import {
  Container,
  Paper,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import ClassList from '../teacher/ClassList';
import StudentTable from '../teacher/StudentTable';
import { useState } from 'react';
import '../../../styles/teacherdashboard.css';
import gradeContent from '../../../components/grades/gradeContent';

export default function TeacherDashboard() {
  const { userProfile } = useAuth();
  const {
    classes,
    loading,
    error,
    selectedClass,
    classStudents,
    handleCreateClass,
    handleDeleteClass,
    loadClassStudents
  } = useClassManagement();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(1);

  const handleDeleteConfirm = async () => {
    const success = await handleDeleteClass(selectedClass.id);
    if (success) {
      setDeleteDialogOpen(false);
    }
  };

  if (!userProfile) {
    return (
      <Container maxWidth="sm">
        <Box className="loading-container">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (userProfile.role !== 'teacher') {
    return (
      <Container maxWidth="sm">
        <Alert severity="error" className="access-denied-alert">
          Access denied. This dashboard is only for teachers.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box className="dashboard-container">
        <Paper className="dashboard-paper">
          <Box className="dashboard-header">
            <Typography className="dashboard-title">
              Teacher Dashboard
            </Typography>
          </Box>

          <ClassList
            classes={classes}
            loading={loading}
            error={error}
            selectedClass={selectedClass}
            onCreateClass={handleCreateClass}
            onSelectClass={loadClassStudents}
          />

          {selectedClass && (
            <Box className="selected-class-container">
              <Box className="class-header">
                <Typography className="selected-class-title">
                  {selectedClass.name}
                </Typography>
                <FormControl className="grade-select">
                  <InputLabel>Grade Level</InputLabel>
                  <Select
                    value={selectedGrade}
                    label="Grade Level"
                    onChange={(e) => setSelectedGrade(e.target.value)}
                  >
                    {Object.keys(gradeContent).map(grade => (
                      <MenuItem key={grade} value={parseInt(grade)}>
                        Grade {grade}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <StudentTable 
                students={classStudents} 
                selectedGrade={selectedGrade}
                gradeContent={gradeContent[selectedGrade]}
              />
              <Box className="delete-class-container">
                <Button
                  variant="contained"
                  className="delete-class-button"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete Class
                </Button>
              </Box>
            </Box>
          )}

          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            className="delete-class-dialog"
          >
            <DialogTitle>Delete Class</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete {selectedClass?.name}? This action is permanent and cannot be undone. All student data associated with this class will be removed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDeleteDialogOpen(false)}
                className="cancel-button"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteConfirm}
                className="confirm-delete-button"
                variant="contained"
              >
                Delete Class
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
    </Container>
  );
}
