import { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import AnalogClock from './AnalogClock';
import '../../../styles/practice.css';

export default function TimePractice({ level, onAnswer, currentProgress, inputs: inputsFn }) {
  const [answer, setAnswer] = useState('');
  const [problem, setProblem] = useState(null);

  // Generate initial problem
  useEffect(() => {
    if (!problem) {
      const newInputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
      setProblem({ hour: newInputs.hour, minute: newInputs.minute });
    }
    // eslint-disable-next-line 
  }, [inputsFn]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!problem) return;

    const { hour, minute } = problem;
    const expectedHour = hour > 12 ? hour - 12 : hour;
    const expectedAnswer = minute === 0 
      ? `${expectedHour}:00`
      : `${expectedHour}:${minute}`;

    if (answer.replace(/\s/g, '') === expectedAnswer) {
      onAnswer('correct', expectedAnswer);
      // Generate new problem only after correct answer
      const newInputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
      setProblem({ hour: newInputs.hour, minute: newInputs.minute });
      setAnswer('');
    } else {
      onAnswer('incorrect', expectedAnswer);
      setAnswer('');
    }
  };

  if (!problem) return null;

  return (
    <form onSubmit={handleSubmit} className="content-box">
      <Box className="clock-container">
        <AnalogClock hour={problem.hour} minute={problem.minute} />
        <TextField
          variant="outlined"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="hh:mm"
          autoComplete="off"
          autoFocus
          className="number-input"
          size="small"
        />
      </Box>
    </form>
  );
}
