import { roll } from './utils';

export const subtraction = {
  "Subtract (up to 10)" : {
    levels: [
      { id: "p8v5q2r3", description: "Count Down by 1 from 10", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 10, increment: -1 } },
      { id: "m5x9v2q6", description: "Subtracting 1", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(2, 8), 
        num2: 1, 
        operation: '-' 
      })},
      { id: "h1y5f9l2", description: "Subtracting 2", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(3, 9), 
        num2: 2, 
        operation: '-' 
      })},
      { id: "z9x3v7q1", description: "Subtracting 3", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(4, 9), 
        num2: 3, 
        operation: '-' 
      })},
      { id: "s7b4c9n3", description: "Subtracting from 5", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: 5, 
        num2: roll(1, 4), 
        operation: '-' 
      })},
      { id: "m1x8v2q7", description: "Subtracting from 10", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: 10, 
        num2: roll(1, 9), 
        operation: '-' 
      })},
      { id: "j5r8t2k9", description: "Subtract Fact Families to 5", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const part1 = roll(1, 4);
        const part2 = roll(1, 5 - part1);
        const whole = part1 + part2;
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: whole, 
          num2: part1, 
          operation: '-',
          missingPosition 
        };
      }},
      { id: "w4y7h1n6", description: "Subtract Fact Families to 10", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const part1 = roll(1, 9);
        const part2 = roll(1, 10 - part1);
        const whole = part1 + part2;
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: whole, 
          num2: part1, 
          operation: '-',
          missingPosition 
        };
      }}
    ]
  }
};
