import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { exerciseComponents, customUIComponents, functionInputComponents } from '../math/config/practiceComponents';
import { areProblemsEqual, generateNewProblem } from '../math/utils/practiceUtils';
import PracticeHeader from '../math/components/PracticeHeader';
import PracticeExercise from '../math/components/PracticeExercise';
import gradeContent from './gradeContent';
import '../../styles/practice.css';
import '../../styles/grades/gradetest.css';

const DEFAULT_TIME_LIMIT = 30;

const GradeTest = () => {
  const { grade } = useParams();
  const { currentUser } = useAuth();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [testComplete, setTestComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [currentProblem, setCurrentProblem] = useState(null);
  const lastProblemRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(DEFAULT_TIME_LIMIT);
  const [startTime, setStartTime] = useState(null);
  // eslint-disable-next-line
  const [error, setError] = useState('');

  useEffect(() => {
    const generateQuestions = () => {
      const gradeNum = parseInt(grade);
      const content = gradeContent[gradeNum];
      
      if (!content) {
        console.error('No content found for grade:', grade);
        return;
      }

      const allQuestions = [];
      
      Object.entries(content).forEach(([topicName, topicData]) => {
        if (topicData.levels) {
          topicData.levels.forEach((level, index) => {
            allQuestions.push({
              ...level,
              topic: topicName,
              level: index + 1
            });
          });
        }
      });

      if (allQuestions.length === 0) {
        console.error('No questions found in grade content');
        return;
      }

      const selectedQuestions = [];
      const totalQuestions = Math.min(25, allQuestions.length);
      const tempQuestions = [...allQuestions];
      
      while (selectedQuestions.length < totalQuestions) {
        const randomIndex = Math.floor(Math.random() * tempQuestions.length);
        const question = tempQuestions.splice(randomIndex, 1)[0];
        selectedQuestions.push(question);
      }

      setQuestions(selectedQuestions);
      
      // Restore progress from localStorage if it exists
      const savedProgress = localStorage.getItem(`gradeTest_${grade}_progress`);
      if (savedProgress) {
        const { questionIndex, currentScore } = JSON.parse(savedProgress);
        if (questionIndex < selectedQuestions.length) {
          setCurrentQuestionIndex(questionIndex);
          setScore(currentScore);
        }
      }
      
      setLoading(false);
      setStartTime(Date.now());
    };

    generateQuestions();
  }, [grade]);

  // Save progress to localStorage when currentQuestionIndex or score changes
  useEffect(() => {
    if (!loading && questions.length > 0) {
      localStorage.setItem(`gradeTest_${grade}_progress`, JSON.stringify({
        questionIndex: currentQuestionIndex,
        currentScore: score
      }));
    }
  }, [currentQuestionIndex, score, grade, loading, questions]);

  // Timer effect
  useEffect(() => {
    if (!startTime || testComplete) return;

    const timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
      const remaining = DEFAULT_TIME_LIMIT - elapsedSeconds;

      if (remaining <= 0) {
        handleAnswer('timeout', '');
        setStartTime(Date.now());
      } else {
        setTimeLeft(remaining);
      }
    }, 100);

    return () => clearInterval(timerInterval);
    // eslint-disable-next-line
  }, [startTime, testComplete]);

  // Generate new problem when question changes
  useEffect(() => {
    if (!questions.length || currentQuestionIndex >= questions.length) return;

    const currentQuestion = questions[currentQuestionIndex];
    if (currentQuestion && !customUIComponents.includes(currentQuestion.exerciseType)) {
      const ExerciseComponent = exerciseComponents[currentQuestion.exerciseType];
      if (ExerciseComponent) {
        let newProblem;
        let attempts = 0;
        const maxAttempts = 10;

        do {
          newProblem = generateNewProblem(ExerciseComponent, currentQuestion, functionInputComponents);
          attempts++;
        } while (
          areProblemsEqual(newProblem, lastProblemRef.current) && 
          attempts < maxAttempts
        );

        setCurrentProblem(newProblem);
        lastProblemRef.current = newProblem;
      }
    }
  }, [currentQuestionIndex, questions]);

  const handleAnswer = async (selectedAnswer, correctAnswer) => {
    if (!questions.length || currentQuestionIndex >= questions.length) return;

    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect = selectedAnswer === 'correct' || selectedAnswer === correctAnswer;

    if (isCorrect) {
      setScore(prev => prev + 1);
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          completedLevels: arrayUnion(currentQuestion.id)
        });
      } catch (error) {
        console.error('Error updating completed levels:', error);
      }
    } else {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        await updateDoc(userRef, {
          completedLevels: arrayRemove(currentQuestion.id)
        });
      } catch (error) {
        console.error('Error updating completed levels:', error);
      }
    }

    setInputValue('');
    lastProblemRef.current = null;
    setStartTime(Date.now());

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prev => prev + 1);
    } else {
      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();
        
        const testScores = userData.testScores?.[grade] || [];
        const newScore = {
          score: score + (isCorrect ? 1 : 0),
          total: questions.length,
          date: new Date().toISOString()
        };

        await updateDoc(userRef, {
          [`testScores.${grade}`]: [...testScores, newScore]
        });

        // Clear progress from localStorage when test is complete
        localStorage.removeItem(`gradeTest_${grade}_progress`);
        setTestComplete(true);
      } catch (error) {
        console.error('Error saving test score:', error);
      }
    }
  };

  if (loading) {
    return <div className="practice-page">Loading test questions...</div>;
  }

  if (questions.length === 0) {
    return <div className="practice-page">No questions available for this grade level</div>;
  }

  if (testComplete) {
    return (
      <div className="practice-page">
        <div className="test-complete">
          <h2>Test Complete!</h2>
          <p>Your score: {score} out of {questions.length}</p>
          <p>Percentage: {Math.round((score / questions.length) * 100)}%</p>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="practice-page">
      <PracticeHeader
        grade={grade}
        topic="Grade Test"
        level="1"
        progress={{ completed: currentQuestionIndex + 1 }}
        ANSWERS_TO_LEVEL_UP={questions.length}
        timeLeft={timeLeft}
        levelComplete={testComplete}
      />

      {error && <div className="error-message">{error}</div>}

      <PracticeExercise
        levelData={currentQuestion}
        currentProblem={currentProblem}
        inputValue={inputValue}
        setInputValue={setInputValue}
        handleAnswer={handleAnswer}
        level={1}
        progress={{ completed: currentQuestionIndex + 1 }}
        lastProblemRef={lastProblemRef}
        isTest={true}
      />
    </div>
  );
};

export default GradeTest;
