import { roll } from './utils';

export const addition = {
  "Add (up to 10)": {
    levels: [
      { id: "xj2k9m4n", description: "Count Up by 1 to 10",  exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 1, increment: 1 } },
      { id: "s8b3c7n1", description: "Adding 1", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(1, 8), 
        num2: 1, 
        operation: '+' 
      })},
      { id: "k4w8r3t7", description: "Adding 2", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(1, 7), 
        num2: 2, 
        operation: '+' 
      })},
      { id: "d6b2c8n4", description: "Adding 3", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(1, 6), 
        num2: 3, 
        operation: '+' 
      })},
      { id: "s8b3c7n2", description: "Adding to 1", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: 1,
        num2: roll(2, 4),
        operation: '+' 
      })},
      { id: "t5w9r4h8", description: "Adding numbers up to 5", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const num1 = roll(1, 3);
        const num2 = roll(2, 5 - num1);
        return { num1, num2, operation: '+' };
      }},
      { id: "d6b2c888", description: "Adding 4", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(2, 6), 
        num2: 4, 
        operation: '+' 
      })},
      { id: "s8b3c7n3", description: "Adding to 2 and 3", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(2, 3),
        num2: roll(5, 8),
        operation: '+' 
      })},
      { id: "g2y6f1l5", description: "Adding numbers up to 10", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const num1 = roll(1, 9);
        const num2 = roll(1, 10 - num1);
        return { num1, num2, operation: '+' };
      }},
      { id: "f3k9p2m7", description: "Addition Fact Families to 5", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const part1 = roll(1, 4);
        const part2 = roll(1, 5 - part1);
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: part1, 
          num2: part2, 
          operation: '+',
          missingPosition 
        };
      }},
      { id: "h8n4q7w2", description: "Addition Fact Families to 10", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const part1 = roll(1, 9);
        const part2 = roll(1, 10 - part1);
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: part1, 
          num2: part2, 
          operation: '+',
          missingPosition 
        };
      }},
      { id: "h8n4q7p9", description: "Complements of 10", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const part1 = roll(1, 9);
        const part2 = 10 - part1;
        const positions = ['num1', 'num2', 'answer'];
        const missingPosition = positions[roll(0, 1)];
        return { 
          num1: part1, 
          num2: part2, 
          operation: '+',
          missingPosition 
        };
      }}
    ]
  }
};
