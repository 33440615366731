import { useState, useEffect } from 'react';
import { Typography, Box, TextField } from '@mui/material';
import '../../../styles/practice.css';

export default function VerticalOperationsPractice({ level, onAnswer, currentProgress, inputs: inputsFn }) {
  const [answer, setAnswer] = useState('');
  const [problem, setProblem] = useState(null);

  // Generate new problem when level or progress changes
  useEffect(() => {
    if (typeof inputsFn === 'function') {
      setProblem(inputsFn());
    }
    setAnswer('');
  }, [level, currentProgress, inputsFn]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!problem) return;

    let expectedAnswer;
    switch (problem.operation) {
      case '+':
        expectedAnswer = problem.num1 + problem.num2;
        break;
      case '-':
        expectedAnswer = problem.num1 - problem.num2;
        break;
      case '*':
        expectedAnswer = problem.num1 * problem.num2;
        break;
      case '÷':
        expectedAnswer = problem.num1 / problem.num2;
        break;
      default:
        expectedAnswer = 0;
    }

    if (answer === expectedAnswer.toString()) {
      onAnswer('correct', 'correct');
      setAnswer('');
    } else {
      onAnswer('incorrect', 'correct');
      setAnswer('');
    }
    // Generate new problem after every answer attempt
    setProblem(inputsFn());
  };

  if (!problem) return null;

  // Format numbers to ensure they line up properly
  const formatNumber = (num) => {
    return num.toString().padStart(2, ' ');
  };

  return (
    <form onSubmit={handleSubmit} className="content-box">
      <Box className="vertical-operation">
        <Box className="operation-numbers">
          <Typography className="number" style={{ fontFamily: 'monospace' }}>{formatNumber(problem.num1)}</Typography>
          <Box className="operation-line-container">
            <Typography className="operation-symbol">{problem.operation}</Typography>
            <Typography className="number" style={{ fontFamily: 'monospace' }}>{formatNumber(problem.num2)}</Typography>
          </Box>
          <div className="operation-line"></div>
          <TextField
            variant="standard"
            value={answer}
            onChange={(e) => setAnswer(e.target.value.replace(/\D/g, ''))}
            autoComplete="off"
            autoFocus
            className="number-input"
            InputProps={{
              disableUnderline: true,
              style: { fontFamily: 'monospace' }
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
