import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function useAuthForm(isRegister = false) {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    ...(isRegister && {
      passwordConfirm: '',
      role: 'student'
    })
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login, signup } = useAuth();
  const navigate = useNavigate();

  const validateUsername = (username) => {
    if (username.length < 3) {
      throw new Error('Username must be at least 3 characters');
    }
    if (!/^[a-zA-Z0-9_]+$/.test(username)) {
      throw new Error('Username can only contain letters, numbers, and underscores');
    }
  };

  const validatePassword = (password, passwordConfirm) => {
    if (password.length < 6) {
      throw new Error('Password must be at least 6 characters');
    }
    if (isRegister && password !== passwordConfirm) {
      throw new Error('Passwords do not match');
    }
  };

  const handleInputChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      validateUsername(formData.username);
      validatePassword(formData.password, formData.passwordConfirm);

      setError('');
      setLoading(true);

      if (isRegister) {
        await signup(formData.username, formData.password, formData.role);
      } else {
        await login(formData.username, formData.password);
      }

      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    formData,
    error,
    loading,
    handleInputChange,
    handleSubmit
  };
}
