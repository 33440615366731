import { roll, generateTwoDigitPlusOneDigit, generateTwoDigitMinusOneDigit } from './utils';

export const placeValue = {
  "Place Value (Tens and Ones)": {
    levels: [
      { id: "w7t3y6u9", description: "Count Up by 1 from 11", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 11, increment: 1 } },
      { id: "h4f8l1s5", description: "Count Down by 1 from 20", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 20, increment: -1 } },
      { id: "x7t3y6u9", description: "Count Up by 1 from 21", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 21, increment: 1 } },
      { id: "a4f8l1s5", description: "Count Down by 1 from 30", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 30, increment: -1 } },
      { id: "w7t3y6u8", description: "Count Up by 1", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: roll(22, 89), increment: 1 } },
      { id: "h4f8l1s4", description: "Count Down by 1", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: roll(21,98), increment: -1 } },
      { id: "f4y8l2s5", description: "Count Up by 10", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 10, increment: 10 } },
      { id: "b7c1n6m3", description: "Count Down by 10 from 100", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 100, increment: -10 } },
      { id: "m7k2h9f4", description: "Ones to Tens", exerciseType: "ConversionPractice", timeLimit: 30, inputs: () => ({
        values: { one: roll(1, 9) * 10 },
        fromUnit: 'one',
        toUnit: 'ten',
        type: 'place_value'
      })},
      { id: "c5v8b2n4", description: "Tens to Ones", exerciseType: "ConversionPractice", timeLimit: 30, inputs: () => ({
        values: { ten: roll(1, 9) },
        fromUnit: 'ten',
        toUnit: 'one',
        type: 'place_value'
      })},
      { id: "q9x4v8k2", description: "Ones Place", exerciseType: "PlaceValuePractice", timeLimit: 30, inputs: () => ({ 
        number: roll(1,99), 
        places: [1] 
      })},
      { id: "w3t7r1h5", description: "Tens Place", exerciseType: "PlaceValuePractice", timeLimit: 30, inputs: () => ({ 
        number: roll(1,99), 
        places: [10] 
      })},
      { id: "g6y2f9l4", description: "Tens Plus Ones", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: { generateProblem: () => ({ 
        num1: roll(1, 9) * 10, 
        num2: roll(1, 9), 
        operation: '+' 
      })}},
      { id: "g6y2f9m8", description: "Add 10", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: { generateProblem: () => ({ 
        num1: roll(11, 89), 
        num2: 10, 
        operation: '+' 
      })}},
      { id: "g6y2f9l5", description: "Subtract 10", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: { generateProblem: () => ({ 
        num1: roll(11, 89), 
        num2: 10, 
        operation: '-' 
      })}},
      { id: "g6y2f9l9", description: "Add 10s", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: { generateProblem: () => ({ 
        num1: roll(11, 49), 
        num2: roll(2,5)*10, 
        operation: '+' 
      })}},
      { id: "g6y2f3l9", description: "Subtract 10s", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: { generateProblem: () => ({ 
        num1: roll(49, 99), 
        num2: roll(2,4)*10, 
        operation: '-' 
      })}},
      { id: "v7n3m2k9", description: "Two-Digit Plus One-Digit (No Regrouping)", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: { generateProblem: generateTwoDigitPlusOneDigit }},
      { id: "l5k2j8h4", description: "Two-Digit Minus One-Digit (No Regrouping)", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: { generateProblem: generateTwoDigitMinusOneDigit }}
    ]
  }
};
