import { roll } from './utils';

export const division = {
    "Division" : {
      levels: [
        { id: "d1v2t3n0", description: "Divide by 2", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 2;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v5t3n0", description: "Divide by 5", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 5;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v0t3n0", description: "Divide by 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 10;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v25t3n", description: "Division with 2, 5, or 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = [2, 5, 10][roll(0, 2)];
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v3t3n0", description: "Divide by 3", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 3;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v4t3n0", description: "Divide by 4", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 4;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v34t3n", description: "Division with 3 or 4", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = [3, 4][roll(0, 1)];
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1vm1s34", description: "Missing Dividend with 2,3,4, or 5", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = roll(2, 5);
          const num1 = num2 * roll(2, 10);
          return {
            num1,
            num2,
            operation: '÷',
            missingPosition: 'num1'
          };
        }},
        { id: "d1v6t3n0", description: "Divide by 6", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 6;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v9t3n0", description: "Divide by 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 9;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v8t3n0", description: "Divide by 8", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 8;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v7t3n0", description: "Divide by 7", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = 7;
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1v6789n", description: "Division with 6,7,8 or 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = roll(6, 9);
          const num1 = num2 * roll(2, 10);
          return { num1, num2, operation: '÷' };
        }},
        { id: "d1vm6789", description: "Missing Dividend with 6,7,8 or 9", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
          const num2 = roll(6, 9);
          const num1 = num2 * roll(2, 10);
          return {
            num1,
            num2,
            operation: '÷',
            missingPosition: 'num1'
          };
        }}
      ]
    }
};
