import CountingPractice from '../practices/CountingPractice';
import OperationsPractice from '../practices/OperationsPractice';
import HalvesPractice from '../practices/HalvesPractice';
import PlaceValuePractice from '../practices/PlaceValuePractice';
import TimePractice from '../practices/TimePractice';
import MoneyPractice from '../practices/MoneyPractice';
import VerticalOperationsPractice from '../practices/VerticalOperationsPractice';
import MultipleChoicePractice from '../practices/MultipleChoicePractice';
import ConversionPractice from '../practices/ConversionPractice';
import BalancedEquationPractice from '../practices/BalancedEquationPractice';
import NamingFractionsPractice from '../practices/NamingFractionsPractice';

// Map of exercise types to components
export const exerciseComponents = {
  CountingPractice,
  OperationsPractice,
  HalvesPractice,
  PlaceValuePractice,
  TimePractice,
  MoneyPractice,
  VerticalOperationsPractice,
  MultipleChoicePractice,
  ConversionPractice,
  BalancedEquationPractice,
  NamingFractionsPractice
};

// Components that have their own UI
export const customUIComponents = [
  'CountingPractice', 
  'TimePractice', 
  'VerticalOperationsPractice', 
  'MoneyPractice',
  'NamingFractionsPractice'
];

// Components that use function-based inputs
export const functionInputComponents = [
  'OperationsPractice', 
  'HalvesPractice', 
  'MultipleChoicePractice', 
  'ConversionPractice', 
  'TimePractice', 
  'BalancedEquationPractice',
  'NamingFractionsPractice'
];

// Components that use inline input
export const inlineInputComponents = [
  'OperationsPractice', 
  'ConversionPractice', 
  'HalvesPractice', 
  'BalancedEquationPractice'
];
