import { Container, Typography, Paper } from '@mui/material';
import '../../styles/legal/Legal.css';

function Privacy() {
  return (
    <div className="legal-container">
      <Container maxWidth="md">
        <Paper className="legal-paper" sx={{ p: 4 }}>
          <Typography variant="h4" component="h1" className="legal-title">
            Privacy Policy
          </Typography>
          
          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            1. Information We Collect
          </Typography>
          <Typography paragraph className="legal-text">
            We collect information you provide directly to us, including name, email address, and academic progress data.
            This information is used to provide and improve our educational services.
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            2. How We Use Your Information
          </Typography>
          <Typography paragraph className="legal-text">
            We use the information we collect to:
            • Provide, maintain, and improve our services
            • Track your progress and customize your learning experience
            • Communicate with you about our services
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            3. Data Security
          </Typography>
          <Typography paragraph className="legal-text">
            We implement appropriate security measures to protect your personal information.
            Your data is encrypted and stored securely.
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            4. Data Sharing
          </Typography>
          <Typography paragraph className="legal-text">
            We do not sell or share your personal information with third parties except as necessary to provide our services
            or as required by law.
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            5. Contact Us
          </Typography>
          <Typography paragraph className="legal-text">
            If you have any questions about this Privacy Policy, please contact us at admin@goatedmath.com
          </Typography>

          <Typography variant="body2" className="legal-updated" sx={{ mt: 4 }}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
        </Paper>
      </Container>
    </div>
  );
}

export default Privacy;
