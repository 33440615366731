import { roll } from './utils';

export const placeValue = {
  "Place Value (Hundreds)": {
    levels: [
      { id: "d8l2s7b4", description: "Count Up by 100 from 100", exerciseType: "CountingPractice", timeLimit: 20, inputs: { start: 100, increment: 100 } },
      { id: "d8l2s7p9", description: "Count Down by 100 from 1000", exerciseType: "CountingPractice", timeLimit: 20, inputs: { start: 1000, increment: -100 } },
      { id: "k9m4n7p2", description: "Count Up by 100", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: roll(11, 99), increment: 100}},
      { id: "k9m4n7r5", description: "Count Down by 100", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: roll(911, 999), increment: -100}},
      { 
        id: "q9x4v8m2", 
        description: "Place Value of Three Digit Numbers", 
        exerciseType: "PlaceValuePractice", 
        timeLimit: 30, 
        inputs: () => {
          return {
            number: roll(100, 999),
            places: [10**roll(0,2)]
          }
        }
      },
      { id: "r4tzy9u2", description: "Convert to 100", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['one', 'ten', 'hundred'];
        const fromIndex = 2;
        let toIndex;
        do {
          toIndex = roll(0, 1);
        } while (toIndex === fromIndex);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        const value = roll(1,9)

        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'place_value'
        };
      }},
      { id: "r4t7y9u2", description: "Convert Between Place Values", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        const units = ['one', 'ten', 'hundred'];
        const fromIndex = roll(0, 2);
        let toIndex;
        do {
          toIndex = roll(0, 2);
        } while (toIndex === fromIndex);
        
        const fromUnit = units[fromIndex];
        const toUnit = units[toIndex];
        
        let value;
        if (fromUnit === 'one') {
          value = roll(1, 9) * (toUnit === 'hundred' ? 100 : 10);
        } else if (fromUnit === 'ten') {
          value = roll(1, 9) * (toUnit === 'hundred' ? 10 : 1);
        } else {
          value = roll(1, 9);
        }
        
        return {
          values: { [fromUnit]: value },
          fromUnit,
          toUnit,
          type: 'place_value'
        };
      }},
      { id: "s5d8f2h7", description: "Add 100", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(100, 899), 
        num2: 100, 
        operation: '+' 
      })},
      { id: "j4k7l2p9", description: "Subtract 100", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(200, 999), 
        num2: 100, 
        operation: '-' 
      })},
      { id: "w2e5r8t3", description: "Add Hundreds", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(100, 500), 
        num2: roll(2, 4) * 100, 
        operation: '+' 
      })},
      { id: "y6u9i3o7", description: "Subtract Hundreds", exerciseType: "VerticalOperationsPractice", timeLimit: 30, inputs: () => ({ 
        num1: roll(500, 999), 
        num2: roll(2, 4) * 100, 
        operation: '-' 
      })}
    ]
  }
};
