import { Paper } from '@mui/material';
import '../../styles/footer.css'

function Footer() {
  return (
    <Paper className="footer-paper" square>
      <div className="footer-container">
        <div className="footer-content">
          <div className="footer-grid">
            <div className="footer-copyright">
              © {new Date().getFullYear()} Goated Math. All rights reserved.
            </div>
            <div className="footer-links">
              <a href="/privacy" className="footer-link">Privacy Policy</a>
              <span className="footer-separator">|</span>
              <a href="/terms" className="footer-link">Terms of Service</a>
              <span className="footer-separator">|</span>
              <a href="mailto:admin@goatedmath.com" className="footer-link">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default Footer;
