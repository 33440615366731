export default function BalancedEquationPractice({ inputs }) {
  const { num1, num2, leftSide } = inputs;
  
  const sum = num1 + num2;
  const missing = sum - leftSide;
  const question = `${num1} + ${num2} = ${leftSide} + _`;

  return {
    problem: {
      question,
      answer: missing.toString()
    },
    validateAnswer: (input) => input.match(/^-?\d*\.?\d+$/)
  };
}
