import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const NamingFractionsPractice = ({ inputs: inputsFn, onAnswer }) => {
    const [userNumerator, setUserNumerator] = useState('');
    const [userDenominator, setUserDenominator] = useState('');
    const [problem, setProblem] = useState(null);

    useEffect(() => {
        if (!problem) {
            const newInputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
            setProblem(newInputs);
        }
        // eslint-disable-next-line 
    }, []);

    const handleInputChange = (value, setter) => {
        if (/^\d*$/.test(value)) {
            setter(value);
        }
    };

    const checkAnswer = () => {
        if (!problem || !userNumerator || !userDenominator) return;

        const isCorrect = parseInt(userNumerator) === problem.numerator && 
                        parseInt(userDenominator) === problem.denominator;
        
        onAnswer(isCorrect ? 'correct' : `${userNumerator}/${userDenominator}`, `${problem.numerator}/${problem.denominator}`);
        
        if (isCorrect) {
            const newInputs = typeof inputsFn === 'function' ? inputsFn() : inputsFn;
            setProblem(newInputs);
        }
        
        setUserNumerator('');
        setUserDenominator('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        checkAnswer();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            checkAnswer();
        }
    };

    const renderCircle = (index, filled) => {
        const segments = [];
        const radius = 50;
        const centerX = 60;
        const centerY = 60;

        for (let i = 0; i < problem.denominator; i++) {
            const startAngle = (i * 360) / problem.denominator - 90;
            const endAngle = ((i + 1) * 360) / problem.denominator - 90;
            const startRad = (startAngle * Math.PI) / 180;
            const endRad = (endAngle * Math.PI) / 180;

            const x1 = centerX + radius * Math.cos(startRad);
            const y1 = centerY + radius * Math.sin(startRad);
            const x2 = centerX + radius * Math.cos(endRad);
            const y2 = centerY + radius * Math.sin(endRad);

            const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

            const pathData = [
                `M ${centerX} ${centerY}`,
                `L ${x1} ${y1}`,
                `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}`,
                'Z'
            ].join(' ');

            segments.push(
                <path
                    key={`${index}-${i}`}
                    d={pathData}
                    fill={i < (filled ? problem.denominator : 0) ? "var(--primary-light)" : "var(--bg-primary)"}
                    stroke="var(--text-primary)"
                    strokeWidth="2"
                />
            );
        }

        return (
            <svg key={index} width="120" height="120" viewBox="0 0 120 120">
                {segments}
            </svg>
        );
    };

    const renderRectangle = (index, filled) => {
        const segments = [];
        const width = 100;
        const height = 50;
        const segmentWidth = width / problem.denominator;

        for (let i = 0; i < problem.denominator; i++) {
            segments.push(
                <rect
                    key={`${index}-${i}`}
                    x={10 + i * segmentWidth}
                    y={10}
                    width={segmentWidth}
                    height={height}
                    fill={i < (filled ? problem.denominator : 0) ? "var(--primary-light)" : "var(--bg-primary)"}
                    stroke="var(--text-primary)"
                    strokeWidth="2"
                />
            );
        }

        return (
            <svg key={index} width="120" height="70" viewBox="0 0 120 70">
                {segments}
            </svg>
        );
    };

    const renderNumberLine = () => {
        const width = 360;
        const height = 100;
        const lineY = height / 2;
        const wholeNumbers = Math.ceil(problem.numerator / problem.denominator);
        const elements = [];

        // Calculate segment width based on whole numbers
        const segmentWidth = (width - 40) / (wholeNumbers * problem.denominator);

        // Main line
        elements.push(
            <line
                key="mainLine"
                x1="20"
                y1={lineY}
                x2={width - 20}
                y2={lineY}
                stroke="var(--text-primary)"
                strokeWidth="2"
            />
        );

        // Tick marks and numbers for whole numbers and fractions
        for (let i = 0; i <= wholeNumbers * problem.denominator; i++) {
            const x = 20 + (i * segmentWidth);
            const isWholeNumber = i % problem.denominator === 0;

            // Tick mark
            elements.push(
                <line
                    key={`tick-${i}`}
                    x1={x}
                    y1={lineY - (isWholeNumber ? 15 : 10)}
                    x2={x}
                    y2={lineY + (isWholeNumber ? 15 : 10)}
                    stroke="var(--text-primary)"
                    strokeWidth={isWholeNumber ? "2" : "1"}
                />
            );

            // Only add numbers for whole numbers
            if (isWholeNumber) {
                elements.push(
                    <text
                        key={`text-${i}`}
                        x={x}
                        y={lineY + 30}
                        textAnchor="middle"
                        fill="var(--text-primary)"
                        fontSize="14"
                    >
                        {i / problem.denominator}
                    </text>
                );
            }
        }

        // Highlight segments
        for (let i = 0; i < problem.numerator; i++) {
            elements.push(
                <line
                    key={`highlight-${i}`}
                    x1={20 + (i * segmentWidth)}
                    y1={lineY}
                    x2={20 + ((i + 1) * segmentWidth)}
                    y2={lineY}
                    stroke="var(--primary-light)"
                    strokeWidth="4"
                />
            );
        }

        return (
            <svg width={width} height={height}>
                {elements}
            </svg>
        );
    };

    const renderShapes = () => {
        if (!problem) return null;

        if (problem.shape === 'numberline') {
            return renderNumberLine();
        }

        const wholeShapes = Math.floor(problem.numerator / problem.denominator);
        const remainder = problem.numerator % problem.denominator;
        const shapes = [];

        // Add whole shapes
        for (let i = 0; i < wholeShapes; i++) {
            shapes.push(
                problem.shape === 'circle' 
                    ? renderCircle(i, true)
                    : renderRectangle(i, true)
            );
        }

        // Add partial shape if there's a remainder
        if (remainder > 0) {
            const partialShape = problem.shape === 'circle'
                ? renderCircle(wholeShapes, false)
                : renderRectangle(wholeShapes, false);

            const segments = partialShape.props.children;
            const filledSegments = segments.map((segment, i) => ({
                ...segment,
                props: {
                    ...segment.props,
                    fill: i < remainder ? "var(--primary-light)" : "var(--bg-primary)"
                }
            }));

            shapes.push(React.cloneElement(partialShape, {}, filledSegments));
        }

        return (
            <div style={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '10px', 
                justifyContent: 'center',
                maxWidth: '400px',
                margin: '0 auto'
            }}>
                {shapes}
            </div>
        );
    };

    if (!problem) return null;

    return (
        <div className="content-box">
            <Typography className="practice-title">
                What fraction is shown below?
            </Typography>
            <div className="visual-container">
                {renderShapes()}
            </div>
            <form onSubmit={handleSubmit} className="fraction-form">
                <div className="fraction-input">
                    <input
                        type="text"
                        value={userNumerator}
                        onChange={(e) => handleInputChange(e.target.value, setUserNumerator)}
                        onKeyDown={handleKeyDown}
                        autoFocus
                    />
                    <hr/>
                    <input
                        type="text"
                        value={userDenominator}
                        onChange={(e) => handleInputChange(e.target.value, setUserDenominator)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </form>
        </div>
    );
};

export default NamingFractionsPractice;
