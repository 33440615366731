import { roll } from './utils';

export const doublesHalves = {
  "Doubles and Halves": {
    levels: [
      { id: "h9y4f2l7", description: "Doubles up to 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const num = roll(6, 10);
        return { num1: num, num2: num, operation: '+' };
      }},
      { id: "z8x2v6q4", description: "Identify Even and Odd Numbers to 20", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => ({
        number: roll(11, 20), choices: ["Even", "Odd"]
      })},
      { id: "g4y8f3l6", description: "Half of Even Numbers to 20", exerciseType: "HalvesPractice", timeLimit: 60, inputs: () => ({
        number: roll(5, 10) * 2
      })},
      { id: "m7x1v4q9", description: "Half of Odd Numbers to 20", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
        number: roll(6, 10) * 2 - 1
      })},
      { id: "h3y7f5l8", description: "Half of All Numbers to 20", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
        number: roll(1, 20)
      })},
      { id: "h9y4f2l6", description: "Doubles of Two Digit Numbers", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const num = roll(1,4)*10 + roll(1,4);
        return { num1: num, num2: num, operation: '+' };
      }},
      { id: "h3y7f50z", description: "Half of Two Digit Even Numbers with Even Tens", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
        number: roll(1,4) * 20 + roll(1,4)*2
      })},
      { id: "h3y4f50z", description: "Half of Two Digit Numbers with Even Tens", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
        number: roll(1,4) * 20 + roll(1,9)
      })}
    ]
  }
};
