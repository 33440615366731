import { useState } from 'react';
import { Typography, TextField } from '@mui/material';
import '../../../styles/practice.css';

export default function MoneyPractice({ level, onAnswer, currentProgress, inputs }) {
  const [answer, setAnswer] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    let isCorrect = false;
    let expectedAnswer = '';

    if (inputs.coin) {
      // Single coin identification
      const coinValues = { penny: '1', nickel: '5', dime: '10' };
      isCorrect = answer === coinValues[inputs.coin];
      expectedAnswer = coinValues[inputs.coin];
    } else {
      // Counting coins
      isCorrect = parseInt(answer) === inputs.total;
      expectedAnswer = inputs.total.toString();
    }

    if (isCorrect) {
      onAnswer('correct', expectedAnswer);
      setAnswer('');
    } else {
      onAnswer('incorrect', expectedAnswer);
      setAnswer('');
    }
  };

  const renderQuestion = () => {
    if (inputs.coin) {
      return `How many cents is a ${inputs.coin}?`;
    } else {
      const coinDisplay = Object.entries(inputs.coins)
        .filter(([_, count]) => count > 0)
        .map(([coin, count]) => `${count} ${coin}${count > 1 ? 's' : ''}`)
        .join(' and ');
      return `How many cents is ${coinDisplay}?`;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="content-box">
      <Typography className="practice-title">
        {renderQuestion()}
      </Typography>
      <TextField
        variant="outlined"
        value={answer}
        onChange={(e) => setAnswer(e.target.value.replace(/\D/g, ''))}
        autoComplete="off"
        autoFocus
        className="number-input"
        size="small"
      />
    </form>
  );
}
