import { Container, Typography, Paper } from '@mui/material';
import '../../styles/legal/Legal.css';

function Terms() {
  return (
    <div className="legal-container">
      <Container maxWidth="md">
        <Paper className="legal-paper" sx={{ p: 4 }}>
          <Typography variant="h4" component="h1" className="legal-title">
            Terms of Service
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph className="legal-text">
            By accessing and using Goated Math, you agree to be bound by these Terms of Service.
            If you do not agree to these terms, please do not use our services.
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            2. User Accounts
          </Typography>
          <Typography paragraph className="legal-text">
            You are responsible for maintaining the confidentiality of your account credentials
            and for all activities that occur under your account. You must immediately notify us
            of any unauthorized use of your account.
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            3. Educational Content
          </Typography>
          <Typography paragraph className="legal-text">
            Our educational content is provided for learning purposes only. While we strive for
            accuracy, we make no guarantees about the completeness or reliability of our content.
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            4. Prohibited Activities
          </Typography>
          <Typography paragraph className="legal-text">
            Users may not:
            • Share account credentials
            • Attempt to bypass security measures
            • Use the service for any unlawful purpose
            • Interfere with other users' access to the service
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            5. Changes to Terms
          </Typography>
          <Typography paragraph className="legal-text">
            We reserve the right to modify these terms at any time. Continued use of our services
            after changes constitutes acceptance of the modified terms.
          </Typography>

          <Typography variant="h6" className="legal-section-title" gutterBottom sx={{ mt: 3 }}>
            6. Contact
          </Typography>
          <Typography paragraph className="legal-text">
            For questions about these Terms of Service, please contact us at admin@goatedmath.com
          </Typography>

          <Typography variant="body2" className="legal-updated" sx={{ mt: 4 }}>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
        </Paper>
      </Container>
    </div>
  );
}

export default Terms;
