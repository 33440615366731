const MultipleChoicePractice = ({ inputs }) => {
  const { number, choices } = inputs;
  const isEven = number % 2 === 0;
  const correctAnswer = isEven ? "Even" : "Odd";

  return {
    problem: {
      question: `Is ${number}:`,
      answer: correctAnswer,
      choices: choices
    },
    validateAnswer: (input) => choices.includes(input)
  };
};

export default MultipleChoicePractice;
