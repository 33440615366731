import { roll, getRandomShape } from './utils';

export const fractions = {
    "Fractions" : {
      levels : [
        { id: "f9n0u1t2", description: "Name Unit Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 60, inputs: () => {
          const denominator = roll(2, 6);
          return {
            numerator: 1,
            denominator,
            shape: getRandomShape()
          };
        }},
        { id: "f9n0u1t3", description: "Name Proper Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 60, inputs: () => {
          const denominator = roll(2, 10);
          return {
            numerator: roll(1, denominator - 1),
            denominator,
            shape: getRandomShape()
          };
        }},
        { id: "f9n0u1t4", description: "Name Improper Fractions", exerciseType: "NamingFractionsPractice", timeLimit: 60, inputs: () => {
          const denominator = roll(2, 6);
          return {
            numerator: roll(denominator + 1, denominator * 2),
            denominator,
            shape: getRandomShape()
          };
        }}
      ]
    }
};
