import { useState, useEffect } from 'react';
import { Typography, TextField, Grid } from '@mui/material';
import '../../../styles/practice.css';

export default function CountingPractice({ level, onAnswer, currentProgress, inputs }) {
  const [answers, setAnswers] = useState(Array(9).fill(''));
  
  // Get start and increment directly from inputs
  const startNumber = inputs.start;
  const countBy = inputs.increment;
  
  const isCountingBackwards = countBy < 0;

  // Reset answers when currentProgress changes
  useEffect(() => {
    if (currentProgress === 1) {
      setAnswers(Array(9).fill(''));
      const firstInput = document.querySelector('input[name="answer-0"]');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, [currentProgress]);

  useEffect(() => {
    setAnswers(Array(9).fill(''));
    const firstInput = document.querySelector('input[name="answer-0"]');
    if (firstInput) {
      firstInput.focus();
    }
  }, [level]);

  const validateSingleAnswer = (index, value) => {
    if (value === '') return false;
    const expectedNumber = startNumber + (countBy * (index + 1));
    return Number(value) === expectedNumber;
  };

  const isBoxEnabled = (index) => {
    if (index === 0 && currentProgress === 1) return true;
    if (index < currentProgress - 1) return false;
    if (index > currentProgress - 1) return false;
    
    const prevAnswer = answers[index - 1];
    if (prevAnswer === '') return false;
    
    return validateSingleAnswer(index - 1, prevAnswer);
  };

  const validateAndProgress = (index) => {
    const currentAnswer = answers[index];
    if (currentAnswer === '') return;
    if (index < currentProgress - 1) return;

    const isCorrect = validateSingleAnswer(index, currentAnswer);

    if (isCorrect) {
      onAnswer('correct', 'correct');
      
      if (index < answers.length - 1) {
        const nextInput = document.querySelector(`input[name="answer-${index + 1}"]`);
        if (nextInput) {
          nextInput.focus();
        }
      }
    } else {
      onAnswer('incorrect', 'correct');
      setAnswers(prev => {
        const newAnswers = [...prev];
        newAnswers[index] = '';
        return newAnswers;
      });
    }
  };

  const handleInputChange = (index, value) => {
    if (value !== '' && !/^-?\d*$/.test(value)) return;
    if (index < currentProgress - 1) return;

    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      validateAndProgress(index);
    }
  };

  useEffect(() => {
    if (currentProgress > 1) {
      const newAnswers = Array(9).fill('');
      for (let i = 0; i < currentProgress - 1; i++) {
        newAnswers[i] = String(startNumber + (countBy * (i + 1)));
      }
      setAnswers(newAnswers);

      const nextInput = document.querySelector(`input[name="answer-${currentProgress - 1}"]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  }, [currentProgress, startNumber, countBy]);

  const inputProps = {
    style: {
      color: 'white',
      WebkitTextFillColor: 'white',
      opacity: 1
    }
  };

  return (
    <div className="content-box">
      <Typography className="practice-title">
        {isCountingBackwards 
          ? `Count backwards by ${Math.abs(countBy)} starting at ${startNumber}`
          : `Count by ${countBy} starting at ${startNumber}`}
      </Typography>
      <br/>

      <Grid container className="grid-container">
        <Grid item>
          <TextField
            disabled
            value={startNumber}
            variant="outlined"
            size="small"
            className="number-input"
            autoComplete="off"
            inputProps={inputProps}
          />
        </Grid>
        {answers.slice(0, 4).map((answer, index) => (
          <Grid item key={index}>
            <TextField
              name={`answer-${index}`}
              value={answer}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              disabled={!isBoxEnabled(index)}
              variant="outlined"
              size="small"
              className="number-input"
              autoComplete="off"
              inputProps={inputProps}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container className="grid-container">
        {answers.slice(4, 9).map((answer, index) => (
          <Grid item key={index + 4}>
            <TextField
              name={`answer-${index + 4}`}
              value={answer}
              onChange={(e) => handleInputChange(index + 4, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index + 4)}
              disabled={!isBoxEnabled(index + 4)}
              variant="outlined"
              size="small"
              className="number-input"
              autoComplete="off"
              inputProps={inputProps}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
