import { roll } from './utils';

export const timeMoney = {
  "Time and Money" : {
    levels :[   
      { id: "z6x8v1q3", description: "Telling Time - Half Hour", exerciseType: "TimePractice", timeLimit: 30, inputs: () => ({
        hour: roll(1, 12),
        minute: roll(0, 1) * 30
      })},
      { id: "t4w3r7h2", description: "Identifying Coins", exerciseType: "ConversionPractice", timeLimit: 30, inputs: () => {
        const coins = ['penny', 'nickel', 'dime', 'quarter', 'half_dollar', 'dollar'];
        const coin = coins[roll(0, coins.length - 1)];
        return {
          values: { [coin]: 1 },
          fromUnit: coin,
          toUnit: 'penny',
          type: 'money'
        };
      }},
      { id: "h5y9f3l7", description: "Convert Between Coins", exerciseType: "ConversionPractice", timeLimit: 45, inputs: () => {
        // Always convert between penny and another coin to ensure unit rates
        const coins = ['nickel', 'dime', 'quarter', 'half_dollar', 'dollar'];
        const otherCoin = coins[roll(0, coins.length - 1)];
        
        // Randomly choose direction (penny to other coin or other coin to penny)
        const toPenny = 1;
        
        return {
          values: { [toPenny ? otherCoin : 'penny']: 1 },
          fromUnit: toPenny ? otherCoin : 'penny',
          toUnit: toPenny ? 'penny' : otherCoin,
          type: 'money'
        };
      }}
   ]
  }
};
