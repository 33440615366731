import useAuthForm from '../../hooks/useAuthForm';
import AuthForm from './AuthForm';

export default function Login() {
  const { formData, error, loading, handleInputChange, handleSubmit } = useAuthForm(false);

  return (
    <AuthForm
      title="Login"
      isRegister={false}
      formData={formData}
      error={error}
      loading={loading}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
    />
  );
}
