import grade1Content from './content/grade1Content';
import grade2Content from './content/grade2Content';
import grade3Content from './content/grade3Content';
import grade4Content from './content/grade4Content';
import grade5Content from './content/grade5Content';
import grade6Content from './content/grade6Content';
import grade7Content from './content/grade7Content';
import grade8Content from './content/grade8Content';

const gradeContent = {
  1: grade1Content,
  2: grade2Content,
  3: grade3Content,
  4: grade4Content,
  5: grade5Content,
  6: grade6Content,
  7: grade7Content,
  8: grade8Content
};

export default gradeContent;
