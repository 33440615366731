import { useState, useRef, useMemo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';

export default function StudentTable({ students, selectedGrade, gradeContent }) {
  const [orderBy, setOrderBy] = useState('username');
  const [order, setOrder] = useState('asc');
  const tableContainerRef = useRef(null);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    if (orderBy !== property) {
      // Set initial sort order based on column type
      setOrder(property === 'username' ? 'asc' : 'desc');
    } else {
      // Toggle sort order if clicking the same column
      setOrder(isAsc ? 'desc' : 'asc');
    }
    setOrderBy(property);
    // Scroll to top when sorting
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const calculateTopicCompletion = (student, topic) => {
    if (!student || !gradeContent) return 0;
    
    const topicData = gradeContent[topic];
    if (!topicData?.levels) return 0;

    let totalLevels = 0;
    let completedLevelsCount = 0;
    const completedLevels = student.completedLevels || [];
    const levelCompletions = student.levelCompletions || {};

    totalLevels = topicData.levels.length;
    
    topicData.levels.forEach(level => {
      if (levelCompletions[level.id] > 0 || completedLevels.includes(level.id)) {
        completedLevelsCount++;
      }
    });

    return totalLevels > 0 ? Math.round((completedLevelsCount / totalLevels) * 100) : 0;
  };

  const calculateTotalCompletion = (student) => {
    if (!student || !gradeContent) return 0;
    
    let totalLevels = 0;
    let completedLevelsCount = 0;

    const completedLevels = student.completedLevels || [];
    const levelCompletions = student.levelCompletions || {};

    Object.values(gradeContent).forEach((topicData) => {
      if (topicData.levels) {
        topicData.levels.forEach(level => {
          totalLevels++;
          if (levelCompletions[level.id] > 0 || completedLevels.includes(level.id)) {
            completedLevelsCount++;
          }
        });
      }
    });

    return totalLevels > 0 ? Math.round((completedLevelsCount / totalLevels) * 100) : 0;
  };

  const timeColumns = [
    { id: 'last24h', label: 'Last 24h (min)' },
    { id: 'last7d', label: 'Last 7 Days (min)' },
    { id: 'lastMonth', label: 'Last Month (min)' },
    { id: 'lifetime', label: 'Lifetime (min)' }
  ];

  const frozenColumns = [
    { id: 'username', label: 'Student' },
    { id: 'totalCompletion', label: 'Total', getValue: (student) => `${calculateTotalCompletion(student)}%` }
  ];

  const columns = [
    ...Object.keys(gradeContent || {}).map(topic => ({
      id: topic,
      label: topic,
      getValue: (student) => `${calculateTopicCompletion(student, topic)}%`
    })),
    ...timeColumns
  ];

  const sortedStudents = useMemo(() => {
    const uniqueStudents = [...new Map(students.map(s => [s.id, s])).values()];
    return [...uniqueStudents].sort((a, b) => {
      if (orderBy === 'username' || timeColumns.some(col => col.id === orderBy)) {
        let comparison = 0;
        if (a[orderBy] < b[orderBy]) comparison = -1;
        if (a[orderBy] > b[orderBy]) comparison = 1;
        return order === 'asc' ? comparison : -comparison;
      } else if (orderBy === 'totalCompletion') {
        const aValue = calculateTotalCompletion(a);
        const bValue = calculateTotalCompletion(b);
        return order === 'asc' ? aValue - bValue : bValue - aValue;
      } else {
        const aValue = calculateTopicCompletion(a, orderBy);
        const bValue = calculateTopicCompletion(b, orderBy);
        return order === 'asc' ? aValue - bValue : bValue - aValue;
      }
    });
//eslint-disable-next-line
  }, [students, orderBy, order, gradeContent]);

  if (students.length === 0) {
    return (
      <Typography className="no-students-message">
        No students have joined this class yet.
      </Typography>
    );
  }

  return (
    <Box className="student-table-container">
      <TableContainer className="table-container" ref={tableContainerRef}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell 
                colSpan={2}
                className="frozen-section header-cell"
              >
                <Box className="frozen-header-content">
                  {frozenColumns.map((column) => (
                    <Box key={column.id} className="frozen-header-cell">
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : column.id === 'username' ? 'asc' : 'desc'}
                        onClick={() => handleSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Box>
                  ))}
                </Box>
              </TableCell>
              {columns.map((column) => (
                <TableCell 
                  key={column.id}
                  className="header-cell"
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'desc'}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedStudents.map((student) => (
              <TableRow key={student.id} className="table-row">
                <TableCell 
                  colSpan={2}
                  className="frozen-section"
                >
                  <Box className="frozen-content">
                    <Box className="frozen-cell">{student.username}</Box>
                    <Box className="frozen-cell">{calculateTotalCompletion(student)}%</Box>
                  </Box>
                </TableCell>
                {columns.map((column) => (
                  <TableCell 
                    key={column.id}
                    className="table-cell"
                  >
                    {column.getValue ? column.getValue(student) : student[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
