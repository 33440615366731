import { Paper, Typography, LinearProgress, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAllGradesProgress from '../../../hooks/useAllGradesProgress';

const gradeStructure = {
  elementary: {
    label: 'Elementary School',
    grades: ['Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5']
  },
  middle: {
    label: 'Middle School',
    grades: ['Grade 6', 'Grade 7', 'Grade 8']
  },
  highSchool: {
    label: 'High School',
    grades: ['Algebra I', 'Geometry', 'Algebra II', 'Pre Calculus', 'Calculus']
  }
};

export default function GradeProgress() {
  const { progressData, loading } = useAllGradesProgress();
  const navigate = useNavigate();

  const handleGradeClick = (grade) => {
    const gradeNumber = grade.match(/\d+/)?.[0] || grade;
    navigate(`/grade/${gradeNumber}`);
  };

  const renderGradeProgress = (grade) => {
    // Convert extracted grade number to a number type to match progressData keys
    const gradeNum = parseInt(grade.match(/\d+/)?.[0]) || grade;
    const progress = progressData?.[gradeNum] || 0;

    return (
      <Box 
        key={grade} 
        className="grade-progress-item"
        onClick={() => handleGradeClick(grade)}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleGradeClick(grade);
          }
        }}
      >
        <Box className="grade-progress-header">
          <Typography variant="subtitle1" className="grade-name">
            {grade}
          </Typography>
          <Typography variant="body2" className="grade-percentage">
            {progress}%
          </Typography>
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={progress} 
          className="grade-progress-bar"
        />
      </Box>
    );
  };

  const renderSchoolLevel = ([key, { label, grades }]) => (
    <Box key={key} className="school-level-section">
      <Typography variant="h6" className="school-level-title">
        {label}
      </Typography>
      <Box className="grade-progress-grid">
        {grades.map(renderGradeProgress)}
      </Box>
    </Box>
  );

  if (loading) {
    return null;
  }

  return (
    <Paper className="dashboard-card grade-progress-card">
      <Typography variant="h6" className="card-title">
        Grade Progress
      </Typography>
      <Box className="grade-progress-container">
        {Object.entries(gradeStructure).map(renderSchoolLevel)}
      </Box>
    </Paper>
  );
}
