import { useState, useEffect } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import '../../../styles/studentdashboard.css';

export default function Classes() {
  const { userProfile, currentUser, joinClass } = useAuth();
  const [enrolledClasses, setEnrolledClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [classCode, setClassCode] = useState('');

  useEffect(() => {
    loadEnrolledClasses();
    // eslint-disable-next-line
  }, [currentUser]);

  async function loadEnrolledClasses() {
    if (!currentUser) return;

    try {
      setError('');
      const classesQuery = query(
        collection(db, 'classes'),
        where('students', 'array-contains', currentUser.uid)
      );
      
      const querySnapshot = await getDocs(classesQuery);
      const classesData = [];
      
      for (const classDoc of querySnapshot.docs) {
        const classData = classDoc.data();
        
        try {
          const teacherRef = doc(db, 'users', classData.teacherId);
          const teacherDoc = await getDoc(teacherRef);
          
          if (!teacherDoc.exists()) {
            classesData.push({
              id: classDoc.id,
              ...classData,
              teacherName: 'Unknown Teacher'
            });
            continue;
          }

          const teacherData = teacherDoc.data();
          
          if (teacherData.role !== 'teacher') {
            classesData.push({
              id: classDoc.id,
              ...classData,
              teacherName: 'Unknown Teacher'
            });
            continue;
          }

          classesData.push({
            id: classDoc.id,
            ...classData,
            teacherName: teacherData.username || 'Unknown Teacher'
          });
        } catch (teacherError) {
          classesData.push({
            id: classDoc.id,
            ...classData,
            teacherName: 'Unknown Teacher'
          });
        }
      }
      
      setEnrolledClasses(classesData);
    } catch (error) {
      setError('Failed to load classes. Please try again later.');
    } finally {
      setLoading(false);
    }
  }

  async function handleJoinClass() {
    if (!classCode.trim()) {
      setError('Please enter a class code');
      return;
    }

    try {
      setError('');
      await joinClass(classCode.trim().toUpperCase());
      setOpenDialog(false);
      setClassCode('');
      await loadEnrolledClasses();
    } catch (error) {
      setError('Failed to join class: ' + error.message);
    }
  }

  if (!userProfile || userProfile.role !== 'student') {
    return (
      <Container maxWidth="sm" className="dashboard-container">
        <Alert severity="error">
          Access denied. This page is only for students.
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="dashboard-container">
      <Paper className="dashboard-paper">
        <Box className="dashboard-header">
          <Typography className="dashboard-title">
            Your Classes
          </Typography>
          <Button
            onClick={() => setOpenDialog(true)}
            className="btn btn-primary hover-elevate"
          >
            Join a Class
          </Button>
        </Box>

        {error && (
          <Alert severity="error" className="dashboard-error">
            {error}
          </Alert>
        )}

        {loading ? (
          <Box className="flex-center">
            <CircularProgress />
          </Box>
        ) : enrolledClasses.length === 0 ? (
          <Typography className="text-secondary">
            You haven't joined any classes yet.
          </Typography>
        ) : (
          <List className="class-list">
            {enrolledClasses.map((classData) => (
              <div key={classData.id}>
                <ListItem className="class-item">
                  <ListItemText
                    primary={
                      <Typography className="font-medium text-secondary">
                        Class: {classData.name}
                      </Typography>
                    }
                    secondary={
                      <Typography className="text-secondary">
                        Teacher: {classData.teacherName}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        )}
      </Paper>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        className="join-dialog"
      >
        <DialogTitle>Join a Class</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Class Code"
            fullWidth
            value={classCode}
            onChange={(e) => setClassCode(e.target.value.toUpperCase())}
            inputProps={{ maxLength: 6 }}
            placeholder="Enter 6-digit code"
            className="class-code-input"
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenDialog(false)}
            className="btn btn-secondary"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleJoinClass} 
            disabled={!classCode.trim()}
            className="btn btn-primary"
          >
            Join
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
