import { addition } from './grade1/addition';
import { subtraction } from './grade1/subtraction';
import { placeValue } from './grade1/placeValue';
import { evenOdd } from './grade1/evenOdd';
import { timeMoney } from './grade1/timeMoney';

const grade1Content = {
  ...addition,
  ...subtraction,
  ...placeValue,
  ...evenOdd,
  ...timeMoney
};

export default grade1Content;
