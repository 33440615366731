import useAuthForm from '../../hooks/useAuthForm';
import AuthForm from './AuthForm';

export default function Register() {
  const { formData, error, loading, handleInputChange, handleSubmit } = useAuthForm(true);

  return (
    <AuthForm
      title="Register"
      isRegister={true}
      formData={formData}
      error={error}
      loading={loading}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
    />
  );
}
