import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

export default function useClassManagement() {
  const { currentUser, userProfile, createClass } = useAuth();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedClass, setSelectedClass] = useState(null);
  const [classStudents, setClassStudents] = useState([]);

  const calculateActivityStats = (intervals) => {
    if (!intervals || intervals.length === 0) return { last24h: 0, last7d: 0, lastMonth: 0, lifetime: 0 };
    
    const now = Date.now() / 1000;
    const oneDay = 24 * 60 * 60;
    const oneWeek = 7 * oneDay;
    const oneMonth = 30 * oneDay;
    
    let stats = {
      last24h: 0,
      last7d: 0,
      lastMonth: 0,
      lifetime: 0
    };

    intervals.forEach(interval => {
      const duration = interval.end - interval.start;
      stats.lifetime += duration;
      
      if (interval.end >= now - oneDay) {
        stats.last24h += duration;
      }
      if (interval.end >= now - oneWeek) {
        stats.last7d += duration;
      }
      if (interval.end >= now - oneMonth) {
        stats.lastMonth += duration;
      }
    });

    return {
      last24h: Math.round(stats.last24h / 60),
      last7d: Math.round(stats.last7d / 60),
      lastMonth: Math.round(stats.lastMonth / 60),
      lifetime: Math.round(stats.lifetime / 60)
    };
  };

  const formatCategoryLevels = (progress) => {
    if (!progress) return {};
    
    return {
      counting: progress.counting?.level || 1,
      operations: progress.operations?.level || 1,
      placeValue: progress.placeValue?.level || 1,
      halves: progress.halves?.level || 1
    };
  };

  const loadClasses = async () => {
    try {
      setLoading(true);
      setError('');
      
      const classesQuery = query(
        collection(db, 'classes'),
        where('teacherId', '==', currentUser.uid)
      );
      
      try {
        const querySnapshot = await getDocs(classesQuery);
        const classesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setClasses(classesData);
        setError('');
      } catch (queryError) {
        if (queryError.code === 'permission-denied') {
          setError('Permission denied. Please check your account permissions.');
        } else if (queryError.code === 'not-found') {
          setClasses([]);
        } else {
          throw queryError;
        }
      }
    } catch (error) {
      console.error('Error loading classes:', error);
      setError('Unable to load classes: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateClass = async (className) => {
    if (!className.trim()) {
      setError('Please enter a class name');
      return false;
    }

    try {
      setError('');
      await createClass(className);
      await loadClasses();
      return true;
    } catch (error) {
      setError('Failed to create class: ' + error.message);
      return false;
    }
  };

  const handleDeleteClass = async (classId) => {
    try {
      setError('');
      await deleteDoc(doc(db, 'classes', classId));
      setSelectedClass(null);
      setClassStudents([]);
      await loadClasses();
      return true;
    } catch (error) {
      setError('Failed to delete class: ' + error.message);
      return false;
    }
  };

  const loadClassStudents = async (classData) => {
    setSelectedClass(classData);
    setClassStudents([]);
    
    try {
      const classDoc = await getDoc(doc(db, 'classes', classData.id));
      if (!classDoc.exists()) {
        throw new Error('Class not found');
      }
      
      const currentClassData = classDoc.data();
      const studentIds = currentClassData.students || [];
      
      if (studentIds.length === 0) {
        setClassStudents([]);
        return;
      }

      const studentPromises = studentIds.map(async (studentId) => {
        try {
          const studentDoc = await getDoc(doc(db, 'users', studentId));
          if (studentDoc.exists()) {
            const studentData = studentDoc.data();
            const activityStats = calculateActivityStats(studentData.activityIntervals || []);
            const categoryLevels = formatCategoryLevels(studentData.progress || {});
            
            return {
              id: studentId,
              username: studentData.username,
              completedLevels: studentData.completedLevels || [],
              levelCompletions: studentData.levelCompletions || {},
              ...categoryLevels,
              ...activityStats
            };
          }
          return null;
        } catch (error) {
          return null;
        }
      });

      const studentProfiles = (await Promise.all(studentPromises))
        .filter(student => student !== null);
      
      setClassStudents(studentProfiles);
      setError('');
    } catch (error) {
      setError('Failed to load student data: ' + error.message);
      setClassStudents([]);
    }
  };

  useEffect(() => {
    if (currentUser && userProfile && userProfile.role === 'teacher') {
      loadClasses();
    }
  }, 
  // eslint-disable-next-line
  [currentUser, userProfile]);
  

  return {
    classes,
    loading,
    error,
    selectedClass,
    classStudents,
    loadClasses,
    handleCreateClass,
    handleDeleteClass,
    loadClassStudents,
    setError
  };
}
