export const roll = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const generateTwoDigitPlusOneDigit = () => {
  const ones2 = roll(1, 8);
  const ones1 = roll(1, 9 - ones2);
  const tens1 = roll(1, 9);
  return {
    num1: tens1 * 10 + ones1,
    num2: ones2,
    operation: '+'
  };
};

export const generateTwoDigitMinusOneDigit = () => {
  const num2 = roll(1, 8);
  const ones1 = roll(num2 + 1, 9);
  const tens1 = roll(1, 9);
  return {
    num1: tens1 * 10 + ones1,
    num2: num2,
    operation: '-'
  };
};
