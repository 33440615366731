import { roll } from './utils';

export const dataMeasurements = {
  'Data and Measurements' : {
    levels: [
      { 
        id: "m2k7p4n9", 
        description: "Unit Conversions", 
        exerciseType: "ConversionPractice", 
        timeLimit: 45, 
        inputs: () => {
          const conversions = [
            { fromUnit: 'foot', toUnit: 'inch', type: 'length' },
            { fromUnit: 'pound', toUnit: 'ounce', type: 'length' },
            { fromUnit: 'year', toUnit: 'day', type: 'time' }
          ];
          const conversion = conversions[roll(0, conversions.length - 1)];
          return {
            values: { [conversion.fromUnit]: 1 },
            fromUnit: conversion.fromUnit,
            toUnit: conversion.toUnit,
            type: conversion.type
          };
        }
      }
    ]
  }
};
