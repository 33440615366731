import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import '../../styles/grades/gradelanding.css';
import '../../styles/grades/gradetest.css';
import gradeContent from './gradeContent';
import useGradeProgress from '../../hooks/useGradeProgress';

const GradeLanding = () => {
  const { grade } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { getLevelCompletions, loading, completionPercentage } = useGradeProgress(grade);
  const [testScores, setTestScores] = useState({ highest: null, latest: null });

  // Reset test scores when grade changes
  useEffect(() => {
    setTestScores({ highest: null, latest: null });
  }, [grade]);

  useEffect(() => {
    const fetchTestScores = async () => {
      if (!currentUser) return;

      try {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const scores = userData.testScores?.[grade] || [];
          
          if (scores.length > 0) {
            const highest = scores.reduce((max, score) => 
              score.score > max.score ? score : max
            , scores[0]);
            
            setTestScores({
              highest: {
                score: highest.score,
                total: highest.total,
                percentage: Math.round((highest.score / highest.total) * 100)
              },
              latest: {
                score: scores[scores.length - 1].score,
                total: scores[scores.length - 1].total,
                percentage: Math.round((scores[scores.length - 1].score / scores[scores.length - 1].total) * 100)
              }
            });
          }
        }
      } catch (error) {
        console.error('Error fetching test scores:', error);
      }
    };

    fetchTestScores();
  }, [currentUser, grade]);

  const content = gradeContent[grade];
  if (!content) {
    return <div className="error-message">Grade level not found</div>;
  }

  if (loading) {
    return <div className="loading">Loading progress...</div>;
  }

  const handleLevelClick = (topicName, levelIndex) => {
    navigate(`/grade/${grade}/${topicName}/${levelIndex + 1}`);
  };

  const handleStartTest = () => {
    navigate(`/grade/${grade}/test`);
  };

  // Transform the content structure into topics array
  const topics = Object.entries(content).map(([topicName, topicData]) => {
    const displayName = topicName.charAt(0).toUpperCase() + topicName.slice(1).replaceAll('_', ' ');
    return {
      name: displayName,
      originalName: topicName,
      levels: topicData.levels.map((level, index) => {
        const completions = getLevelCompletions(topicName, index + 1);
        return {
          ...level,
          completions
        };
      })
    };
  });

  return (
    <div className="grade-landing">
      <div className="grade-header">
        <h1>Grade {grade} Math</h1>
        <div className="completion-status">
          <div className="completion-text">
            Completion: {completionPercentage}%
          </div>
          <div className="completion-bar-container">
            <div className="completion-bar">
              <div 
                className="completion-progress" 
                style={{ width: `${completionPercentage}%` }}
              />
            </div>
            <button className="start-test-btn" onClick={handleStartTest}>
              Take A Test
            </button>
          </div>
        </div>
        <div className="test-scores">
          <div className="scores-container">
            <div className="score-card highest-score">
              <h3>Highest Score</h3>
              {testScores.highest ? (
                <>
                  <div className="score-percentage">{testScores.highest.percentage}%</div>
                  <div className="score-details">
                    {testScores.highest.score} out of {testScores.highest.total} correct
                  </div>
                </>
              ) : (
                <>
                  <div className="score-percentage">--%</div>
                  <div className="score-details">No attempts yet</div>
                </>
              )}
            </div>
            <div className="score-card latest-score">
              <h3>Latest Score</h3>
              {testScores.latest ? (
                <>
                  <div className="score-percentage">{testScores.latest.percentage}%</div>
                  <div className="score-details">
                    {testScores.latest.score} out of {testScores.latest.total} correct
                  </div>
                </>
              ) : (
                <>
                  <div className="score-percentage">--%</div>
                  <div className="score-details">No attempts yet</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="topics">
        {topics.map((topic, index) => (
          <div key={index} className="topic-card">
            <h2>{topic.name}</h2>
            <div className="levels-list">
              {topic.levels.map((level, levelIndex) => (
                <div 
                  key={levelIndex} 
                  className={`level-square ${level.completions > 0 ? 'completed' : ''}`}
                  data-tooltip={level.description}
                  onClick={() => handleLevelClick(topic.originalName, levelIndex)}
                >
                  {level.completions > 0 && (
                    <span className="completion-count">{level.completions}</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GradeLanding;
