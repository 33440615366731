import { addSubtract20 } from './grade2/addSubtract20';
import { placeValue } from './grade2/placeValue';
import { multiDigitOps } from './grade2/multiDigitOps';
import { doublesHalves } from './grade2/doublesHalves';
import { dataMeasurements } from './grade2/dataMeasurements';
import { timeMoney } from './grade2/timeMoney';

const grade2Content = {
  ...addSubtract20,
  ...placeValue,
  ...multiDigitOps,
  ...doublesHalves,
  ...dataMeasurements,
  ...timeMoney
};

export default grade2Content;
