import { memo } from 'react';
import { Typography, Paper } from '@mui/material';
import '../../../styles/studentdashboard.css';

const TimeCard = memo(({ title, time }) => (
  <Paper className="time-card">
    <Typography className="time-card-title">
      {title}
    </Typography>
    <Typography className="time-card-value">
      {formatDuration(time)}
    </Typography>
  </Paper>
));

const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;
  
  const parts = [];
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  parts.push(`${secs}s`);

  return parts.join(' ');
};

const TimeStats = memo(({ timeStats }) => (
  <>
    <Paper className="stats-paper">
      <Typography className="stats-title">
        Practice Time
      </Typography>
      <div className="practice-time-grid">
        <TimeCard title="Last Day" time={timeStats.day} />
        <TimeCard title="Last Week" time={timeStats.week} />
        <TimeCard title="Last Month" time={timeStats.month} />
        <TimeCard title="Total" time={timeStats.lifetime} />
      </div>
    </Paper>
  </>
));

export default TimeStats;
