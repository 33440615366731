import { roll } from './utils';

export const evenOdd = {
  "Even and Odd": {
    levels: [
      { id: "b9c2n7k4", description: "Count Up by 2", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 2, increment: 2 } },
      { id: "m5x8v3q6", description: "Count down by 2", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 20, increment: -2 } },
      { id: "k6w3r8t2", description: "Doubles up to 5", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const num = roll(1, 5);
        return { num1: num, num2: num, operation: '+' };
      }},
      { id: "j1w4r7t2", description: "Count Up by 2 from 1", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 1, increment: 2 } },
      { id: "g6y9h3f5", description: "Count Down by 2 from 19", exerciseType: "CountingPractice", timeLimit: 30, inputs: { start: 19, increment: -2 } },
      { id: "d3b7c1n5", description: "Identify Even and Odd Numbers to 10", exerciseType: "MultipleChoicePractice", timeLimit: 30, inputs: () => ({
        number: roll(1, 10), choices: ["Even", "Odd"]
      })},
      { id: "t1w5r9h3", description: "Half of Even Numbers to 10", exerciseType: "HalvesPractice", timeLimit: 30, inputs: () => ({
        number: roll(1, 5) * 2
      })},
      { id: "s2b6c8n1", description: "Half of Odd Numbers to 10", exerciseType: "HalvesPractice", timeLimit: 30, inputs: () => ({
        number: roll(1, 5) * 2 - 1
      })},
      { id: "k5w9r2t6", description: "Half of All Numbers to 10", exerciseType: "HalvesPractice", timeLimit: 30, inputs: () => ({
        number: roll(1, 10)
      })},
      { id: "k6w3r8a2", description: "Doubles up to 5 Tens", exerciseType: "OperationsPractice", timeLimit: 30, inputs: () => {
        const num = roll(1, 5) * 10;
        return { num1: num, num2: num, operation: '+' };
      }},
      { id: "t1w5r8ha", description: "Half of Even Tens", exerciseType: "HalvesPractice", timeLimit: 30, inputs: () => ({
        number: roll(1, 5) * 2 * 10
      })}
    ]
  }
};
