import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Hero() {
  const navigate = useNavigate();

  return (
    <div className="hero-grid">
      <div className="hero-text">
        <h1 className="hero-title">
          GOATED MATH
        </h1>
        <h2 className="hero-subtitle">
          - Level Up Your Math Skills
          <br/>
          - Become the Math GOAT
        </h2>
        <div className="hero-buttons">
          <Button
            onClick={() => navigate('/register')}
            className="btn btn-primary"
          >
            Start Learning Now
          </Button>
          
          <Button
            onClick={() => navigate('/login')}
            className="btn btn-secondary"
          >
            Sign In
          </Button>
        </div>
      </div>
      <div className="hero-image">
        <img src="/hero.jpg" alt="Math Learning" className="hero-img" />
      </div>
    </div>
  );
}
