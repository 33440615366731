import { roll } from './utils';

export const doublesHalves = {
    "Doubles and Halves" : {
        levels: [
            { id: "r9y4f2l6", description: "Doubles of Two Digit Numbers with 5 in Ones Place", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
                const num = roll(1,4)*10 + 5;
                return { num1: num, num2: num, operation: '+' };
              }},
              { id: "hry7f50z", description: "Half of Odd Tens", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
                number: roll(2,5) * 20 - 10
              })},
              { id: "h3y7f50z", description: "Half of Two Digit Even Numbers with Odd Tens", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
                number: roll(2,5) * 20 - 10 + roll(1,4)*2
              })},
              { id: "zay7f50z", description: "Half of Two Digit Numbers", exerciseType: "HalvesPractice", timeLimit: 75, inputs: () => ({
                number: roll(1,9) * 10 + roll(1,9)
              })}
        ]
    }
};
