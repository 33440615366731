import { Typography, Box, LinearProgress } from '@mui/material';
import BackButton from '../../global/BackButton';

export default function PracticeHeader({ 
  grade, 
  topic, 
  level, 
  progress, 
  ANSWERS_TO_LEVEL_UP,
  timeLeft,
  levelComplete 
}) {
  return (
    <>
      <div className="practice-header">
        <BackButton 
          to={`/grade/${grade}`} 
          tooltip={`Back to Grade ${grade}`}
        />
        <Typography variant="h4" className="practice-title">
          Grade {grade} - {topic.charAt(0).toUpperCase() + topic.slice(1).replace('-', ' ')} - Level {level}
        </Typography>
      </div>

      <Box className="progress-section">
        <Box className="progress-header">
          <Typography>
            Level: {level} - Progress: {progress.completed}/{ANSWERS_TO_LEVEL_UP}
          </Typography>
          {!levelComplete && (
            <Typography className={`timer ${timeLeft <= 5 ? 'timer-warning' : ''}`}>
              Time: {timeLeft}s
            </Typography>
          )}
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={(progress.completed / ANSWERS_TO_LEVEL_UP) * 100} 
          className="progress-bar"
        />
      </Box>
    </>
  );
}
