import { multiplication } from './grade3/multiplication';
import { division } from './grade3/division';
import { doublesHalves } from './grade3/doublesHalves';
import { fractions } from './grade3/fractions';
import { geometry } from './grade3/geometry';
import { dataMeasurements } from './grade3/dataMeasurements';

const grade3Content = {
    ...multiplication,
    ...division,
    ...doublesHalves,
    ...fractions,
    ...geometry,
    ...dataMeasurements
};

export default grade3Content;
