export default function PlaceValuePractice({ inputs }) {
  // If inputs is a function, call it to get the actual inputs object
  const actualInputs = typeof inputs === 'function' ? inputs() : inputs;
  let { number, places } = actualInputs;

  const getPlaceName = (place) => {
    switch(place) {
      case 1: return "ones";
      case 10: return "tens";
      case 100: return "hundreds";
      case 1000: return "thousands";
      case 10000: return "ten thousands";
      case 100000: return "hundred thousands";
      case 1000000: return "millions";
      case 10000000: return "ten millions";
      case 100000000: return "hundred millions";
      case 1000000000: return "billions";
      default: return `${place}'s`;
    }
  };

  const getDigitInPlace = (num, place) => {
    return Math.floor((num % (place * 10)) / place);
  };

  // Ensure we're working with an array and the actual place values
  places = Array.isArray(places) ? places : [places];

  const answer = places
    .map(place => getDigitInPlace(number, place))
    .join(', ');

  const placeNames = places
    .map(place => getPlaceName(place))
    .join(" and ");

  return {
    problem: {
      question: `What digit is in the ${placeNames} place of ${number.toLocaleString()}?`,
      answer
    },
    validateAnswer: (input) => {
      // Allow commas and spaces in input for multiple digits
      const cleaned = input.replace(/\s+/g, '');
      return cleaned.match(/^\d+(?:,\d+)*$/);
    }
  };
}
