import { 
  AppBar, 
  Toolbar,
  Button, 
  Box,
  MenuItem,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import PersonIcon from '@mui/icons-material/Person';
import BarChartIcon from '@mui/icons-material/BarChart';
import useAllGradesProgress from '../../hooks/useAllGradesProgress';
import '../../styles/header.css'

const gradeStructure = {
  elementary: {
    label: 'Elementary School',
    grades: ['Grade 1', 'Grade 2', 'Grade 3', 'Grade 4', 'Grade 5']
  },
  middle: {
    label: 'Middle School',
    grades: ['Grade 6', 'Grade 7', 'Grade 8']
  },
  highSchool: {
    label: 'High School',
    grades: ['Algebra I', 'Geometry', 'Algebra II', 'Pre Calculus', 'Calculus']
  }
};

const PracticeMenu = ({ onPracticeClick, progressData }) => (
  <div className="header-menu-container">
    <IconButton className="transition-fast">
      <BarChartIcon />
    </IconButton>
    <Paper className="header-menu left">
      {Object.entries(gradeStructure).map(([key, { label, grades }]) => (
        <div key={key} className="menu-item-container">
          <MenuItem className="menu-item transition-fast">
            {label}
          </MenuItem>
          <Paper className="submenu">
            {grades.map((grade) => {
              const gradeNum = grade.match(/\d+/)?.[0] || grade;
              const percentage = progressData?.[gradeNum] || 0;
              return (
                <MenuItem
                  key={grade}
                  onClick={() => onPracticeClick(grade)}
                  className="menu-item transition-fast"
                >
                  <span className="grade-label">{grade}</span>
                  <span className="grade-progress">{percentage}%</span>
                </MenuItem>
              );
            })}
          </Paper>
        </div>
      ))}
    </Paper>
  </div>
);

const UserMenu = ({ onNavigate, onLogout, showClasses }) => (
  <div className="header-menu-container">
    <IconButton className="transition-fast">
      <PersonIcon />
    </IconButton>
    <Paper className="header-menu right">
      <MenuItem onClick={() => onNavigate('/dashboard')} className="menu-item transition-fast">
        Dashboard
      </MenuItem>
      {showClasses && (
        <MenuItem onClick={() => onNavigate('/classes')} className="menu-item transition-fast">
          Classes
        </MenuItem>
      )}
      <MenuItem onClick={() => onNavigate('/profile')} className="menu-item transition-fast">
        Settings
      </MenuItem>
      <MenuItem onClick={onLogout} className="menu-item transition-fast">
        Logout
      </MenuItem>
    </Paper>
  </div>
);

const AuthButtons = ({ onLogin, onRegister }) => (
  <div className="header-auth-buttons">
    <Button 
      onClick={onLogin}
      className="btn transition-fast"
    >
      Sign In
    </Button>
    <Button 
      onClick={onRegister}
      className="btn btn-outlined transition-fast hover-elevate"
    >
      Register
    </Button>
  </div>
);

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, logout, userProfile } = useAuth();
  const { progressData } = useAllGradesProgress();

  const isHomePage = location.pathname === '/';
  const showAuthButtons = isHomePage && !currentUser;

  const handlePracticeClick = (grade) => {
    // Extract grade number and navigate to the new URL format
    const gradeNumber = grade.match(/\d+/)?.[0] || grade;
    navigate(`/grade/${gradeNumber}`);
  };

  const handleBrandClick = () => {
    navigate(currentUser ? '/dashboard' : '/');
  };

  return (
    <AppBar className="header">
      <Toolbar className="header-toolbar">
        <Box className="header-icon-container">
          {currentUser && userProfile?.role !== 'teacher' && (
            <PracticeMenu 
              onPracticeClick={handlePracticeClick}
              progressData={progressData}
            />
          )}
        </Box>
        
        <div className="header-brand" onClick={handleBrandClick}>
          <img src="/logo.png" alt="Goated Math" className="header-logo" />
          <Typography variant="h6" className="header-title">
            GOATED MATH
          </Typography>
        </div>

        <Box className="flex-center">
          {showAuthButtons ? (
            <AuthButtons 
              onLogin={() => navigate('/login')}
              onRegister={() => navigate('/register')}
            />
          ) : currentUser && (
            <UserMenu 
              onNavigate={navigate}
              onLogout={logout}
              showClasses={userProfile?.role !== 'teacher'}
            />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
