export default function ConversionPractice({ inputs }) {
  // If inputs is a function, call it to get the actual inputs object
  const actualInputs = typeof inputs === 'function' ? inputs() : inputs;
  const { values, fromUnit, toUnit, type } = actualInputs;

  // Helper function to convert units
  const convert = () => {
    switch(type) {
      case 'money':
        // Convert between all coin types
        const moneyConversions = {
          'penny': 1,
          'nickel': 5,
          'dime': 10,
          'quarter': 25,
          'half_dollar': 50,
          'dollar': 100
        };
        return (values[fromUnit] * moneyConversions[fromUnit]) / moneyConversions[toUnit];
      
      case 'weight':
        // Convert between pounds and ounces
        const weightConversions = {
          'pound': 16, // 1 pound = 16 ounces
          'ounce': 1
        };
        return (values[fromUnit] * weightConversions[fromUnit]) / weightConversions[toUnit];
      
      case 'length':
        // Convert between feet and inches
        const lengthConversions = {
          'foot': 12, // 1 foot = 12 inches
          'inch': 1
        };
        return (values[fromUnit] * lengthConversions[fromUnit]) / lengthConversions[toUnit];

      case 'place_value':
        // Convert between place values
        const placeValueConversions = {
          'one': 1,
          'ten': 10,
          'hundred': 100,
          'thousand': 1000,
          'ten thousand': 10000,
          'hundred thousand': 100000,
          'million': 1000000,
          'ten million': 10000000,
          'hundred million': 100000000,
          'billion': 1000000000
        };
        return (values[fromUnit] * placeValueConversions[fromUnit]) / placeValueConversions[toUnit];
      
      case 'time':
        // Special handling for time conversions
        if (fromUnit === 'year' && toUnit === 'day') {
          return values[fromUnit] * 365;
        } else if (fromUnit === 'week' && toUnit === 'day') {
          return values[fromUnit] * 7;
        } else if (fromUnit === 'day' && toUnit === 'hour') {
          return values[fromUnit] * 24;
        } else if (fromUnit === 'hour' && toUnit === 'minute') {
          return values[fromUnit] * 60;
        } else if (fromUnit === 'minute' && toUnit === 'second') {
          return values[fromUnit] * 60;
        }
        return 0;

      default:
        return 0;
    }
  };

  const answer = convert().toString();
  const unitDisplay = {
    'penny': {
      singular: 'penny',
      plural: 'pennies'
    },
    'nickel': {
      singular: 'nickel',
      plural: 'nickels'
    },
    'dime': {
      singular: 'dime',
      plural: 'dimes'
    },
    'quarter': {
      singular: 'quarter',
      plural: 'quarters'
    },
    'half_dollar': {
      singular: 'half dollar',
      plural: 'half dollars'
    },
    'dollar': {
      singular: 'dollar',
      plural: 'dollars'
    },
    'pound': {
      singular: 'pound',
      plural: 'pounds'
    },
    'ounce': {
      singular: 'ounce',
      plural: 'ounces'
    },
    'foot': {
      singular: 'foot',
      plural: 'feet'
    },
    'inch': {
      singular: 'inch',
      plural: 'inches'
    },
    'one': {
      singular: 'one',
      plural: 'ones'
    },
    'ten': {
      singular: 'ten',
      plural: 'tens'
    },
    'hundred': {
      singular: 'hundred',
      plural: 'hundreds'
    },
    'thousand': {
      singular: 'thousand',
      plural: 'thousands'
    },
    'ten thousand': {
      singular: 'ten thousand',
      plural: 'ten thousands'
    },
    'hundred thousand': {
      singular: 'hundred thousand',
      plural: 'hundred thousands'
    },
    'million': {
      singular: 'million',
      plural: 'millions'
    },
    'ten million': {
      singular: 'ten million',
      plural: 'ten millions'
    },
    'hundred million': {
      singular: 'hundred million',
      plural: 'hundred millions'
    },
    'billion': {
      singular: 'billion',
      plural: 'billions'
    },
    'year': {
      singular: 'year',
      plural: 'years'
    },
    'week': {
      singular: 'week',
      plural: 'weeks'
    },
    'day': {
      singular: 'day',
      plural: 'days'
    },
    'hour': {
      singular: 'hour',
      plural: 'hours'
    },
    'minute': {
      singular: 'minute',
      plural: 'minutes'
    },
    'second': {
      singular: 'second',
      plural: 'seconds'
    }
  };

  // Format question with units before the underscore
  const fromValue = values[fromUnit];
  const fromUnitText = fromValue === 1 ? unitDisplay[fromUnit].singular : unitDisplay[fromUnit].plural;
  // Use 'cents' if penny is the toUnit, otherwise use normal display
  const toUnitText = toUnit === 'penny' ? 'cents' : unitDisplay[toUnit].plural;

  return {
    problem: {
      question: `${fromValue} ${fromUnitText} = _ ${toUnitText}`,
      answer
    },
    validateAnswer: (input) => input.match(/^\d+$/)
  };
}
