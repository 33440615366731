import { roll } from './utils';

export const division = {
  "Multidigit Division": {
    levels: [
      { id: "d4p2n8k5", description: "Divide by 10", exerciseType: "OperationsPractice", timeLimit: 45, inputs: () => {
        const num2 = 10;
        const num1 = num2 * roll(11, 99);
        return { num1, num2, operation: '÷' };
      }}
    ]
  }
};
