import { useAuth } from '../../contexts/AuthContext';
import StudentDashboard from './student/StudentDashboard';
import TeacherDashboard from './teacher/TeacherDashboard';
import { Container, CircularProgress, Box } from '@mui/material';

export default function Dashboard() {
  const { userProfile } = useAuth();

  if (!userProfile) {
    return (
      <Container maxWidth="sm">
        <Box className="loading-container">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return userProfile.role === 'teacher' ? <TeacherDashboard /> : <StudentDashboard />;
}
