// Check if two problems are equal
export const areProblemsEqual = (problem1, problem2) => {
  if (!problem1 || !problem2) return false;
  return JSON.stringify(problem1.problem) === JSON.stringify(problem2.problem);
};

// Map grade numbers to content
export const gradeContentMap = {
  "1": require('../../grades/content/grade1Content').default,
  "2": require('../../grades/content/grade2Content').default,
  "3": require('../../grades/content/grade3Content').default,
  "4": require('../../grades/content/grade4Content').default
};

// Get level data from grade content
export const getLevelData = (grade, topic, level) => {
  const gradeContent = gradeContentMap[grade];
  return gradeContent?.[topic]?.levels[parseInt(level, 10) - 1];
};

// Handle keyboard events for multiple choice
export const handleMultipleChoiceKeyPress = (event, choices, setInputValue) => {
  const num = parseInt(event.key, 10);
  if (num >= 1 && num <= choices.length) {
    const choice = choices[num - 1];
    setInputValue(choice);
  }
};

// Generate new problem for exercise
export const generateNewProblem = (ExerciseComponent, levelData, functionInputComponents) => {
  // Only use function-based inputs for specific components
  const inputs = functionInputComponents.includes(levelData.exerciseType) && typeof levelData.inputs === 'function'
    ? levelData.inputs()
    : levelData.inputs;
  return ExerciseComponent({ inputs });
};
