import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyASYhbO4vltlIrUJf9N0nmDnAxnLQ8h7us",
    authDomain: "math-practice-app-f9871.firebaseapp.com",
    projectId: "math-practice-app-f9871",
    storageBucket: "math-practice-app-f9871.firebasestorage.app",
    messagingSenderId: "152000638720",
    appId: "1:152000638720:web:734806358210f1bdc9cde5"
  };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
